import React, { Component } from "react";
import Slider from "react-slick";
import { Header } from "./layout/Header";
import { CarouselPage } from "./banner/Home1_banner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FaSolarPanel } from "react-icons/fa";
import { FaEarthAmericas } from "react-icons/fa6";
import { FaPeopleArrows } from "react-icons/fa";
import { FaIndustry } from "react-icons/fa";
import { IoHomeSharp } from "react-icons/io5";
import { PiBuildingApartmentFill } from "react-icons/pi";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineCardMembership } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { PiSolarRoofBold } from "react-icons/pi";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import routes from "../constants/routes";
AOS.init();

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

const client1 = "/images/client1.png";
const client2 = "/images/client2.png";
const client3 = "/images/client3.png";
const client4 = "/images/client4.png";
const client5 = "/images/client5.png";
const voice1 = "/images/voice1.jpg";
const voice2 = "/images/voice2.jpg";
const customer1 = "/images/customer1.jpg";
const customer2 = "/images/customer2.jpg";
const customer3 = "/images/customer3.jpg";
const customer4 = "/images/customer4.jpg";
const video = "/video.mp4";

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className='site-main'>
        <Header />
        {/* home banner */}
        <CarouselPage />
        {/* home banner end*/}
        {/* service-section */}
        <div data-aos='fade-right'>
          <section className='clearfix ttm-row service-section position-relative z-1'>
            <div className='container-xl'>
              <div
                className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                style={{ backgroundColor: "#044BA4" }}
              >
                <div className='col-md-4'>
                  {/*  featured-icon-box */}
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white'
                    style={{ backgroundColor: "#044BA4" }}
                  >
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>100+</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>Man Power</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>

                <div className='col-md-4'>
                  {/*  featured-icon-box */}
                  <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>Rapidly growing</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>Roof Top Power Customers</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
                <div className='col-md-4'>
                  {/*  featured-icon-box */}
                  <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>Pan India</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>Operations</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* service-section end */}
        {/* aboutus-section */}
        <br />
        <br />

        <div data-aos='fade-left'>
          <section className='clearfix ttm-row service-section position-relative z-1'>
            <div className='container-xl'>
              <div
                className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                style={{ backgroundColor: "#044BA4" }}
              >
                <div className='col-md-4'>
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    <div className='featured-icon'>
                      {/*  featured-icon */}
                      <div
                        className='ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md'
                        style={{ backgroundColor: "#044BA4" }}
                      >
                        <FaSolarPanel />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>Solar Solutions</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>Perfect solution for solar needs</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  {/*  featured-icon-box */}
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white'
                    style={{ backgroundColor: "#044BA4" }}
                  >
                    <div className='featured-icon'>
                      {/*  featured-icon */}
                      <div
                        className='ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md'
                        style={{ backgroundColor: "#0692D0" }}
                      >
                        <FaEarthAmericas />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>Global Expertise</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>A leading solar service provider</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
                <div className='col-md-4'>
                  {/*  featured-icon-box */}
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    <div className='featured-icon'>
                      {/*  featured-icon */}
                      <div
                        className='ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md'
                        style={{ backgroundColor: "#044BA4" }}
                      >
                        <FaPeopleArrows />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>End to End Solution</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p>Provides End to End Solutions</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* aboutus-section end */}
        {/* history-section */}
        <section className='clearfix ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes'>
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              <div className='col-lg-8 offset-lg-2'>
                {/* section title */}
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                  
                    <h2 className='title'>
                    Empower Your Future with StarpowerX’s Premier Solar Solutions

                    </h2>
                    </div>
                    <br />
                    <div className='title-desc'>
                      <p>
                      StarpowerX Solar Energy is a leading innovator in the solar energy industry, specializing in the installation and servicing of high-efficiency solar panels designed to harness the power of the sun.


                      </p>
                      <p>
                      At StarpowerX, we believe that the foundation of a sustainable future lies in putting our customers first. Our unwavering commitment to excellence drives us to deliver not just products, but complete energy solutions tailored to meet the unique needs of each customer. We pride ourselves on our ability to provide swift and effective responses to any inquiries or service requests, ensuring that our customers experience seamless transitions to solar energy.

                      </p>
                    </div>
                  
                </div>
                {/* section title end */}
              </div>
            </div>
            <br />

            <Slider
              className='row slick_slider ttm-boxes-spacing-30px'
              {...slick_slider}
            >
              <div data-aos='flip-left' data-aos-duration='1000'>
                <div className='ttm-box-col-wrapper'>
                  <div className='ttm-history-box-wrapper'>
                    <div className='featured-thumbnail'>
                      {/* featured-thumbnail */}
                      <img
                        id='logo-img'
                        className='img-fluid'
                        alt='logo'
                        src='images/rooftop1.webp'
                      />
                    </div>
                    {/* <div className="ttm-history-box-icon-wrapper">
              
                       
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                             <FaIndustry />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    <div
                      className='ttm-history-box-border'
                      style={{ backgroundColor: "#044BA4" }}
                    />
                    {/* ttm-history-box-border  */}
                    <div
                      className='ttm-history-box-details '
                      style={{ backgroundColor: "#0692D0" }}
                    >
                      <div className='featured-icon'>
                        <div
                          className='ttm-icon ttm-icon_element-size-md'
                          style={{ color: "#044BA4" }}
                        >
                          <FaIndustry />
                        </div>
                      </div>
                      <div className='ttm-historybox-title'>
                        <h5 style={{ color: "white" }}>
                          Industrial <br /> Rooftops
                        </h5>
                      </div>
                      {/* historybox-title  */}
                      <div
                        className='ttm-historybox-description'
                        style={{ color: "white" }}
                      >
                        {/* description  */}
                        At StarpowerX Solar, we are dedicated to providing
                        cutting-edge Industrial Rooftop Solar
                      </div>
                      <a
                        className='mt-20 ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'
                        style={{ color: "#044BA4" }}
                        href={routes.industrialRooftops}
                      >
                        Read More <i className='ti ti-angle-double-right' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos='flip-left' data-aos-duration='1000'>
                <div className='ttm-box-col-wrapper'>
                  <div className='ttm-history-box-wrapper'>
                    <div className='featured-thumbnail'>
                      {/* featured-thumbnail */}
                      <img
                        id='logo-img'
                        className='img-fluid'
                        alt='logo'
                        src='images/rooftop2.webp'
                      />
                    </div>
                    {/* <div className="ttm-history-box-icon-wrapper">
              
                       
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-size-md">
                             <FaIndustry />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    <div
                      className='ttm-history-box-border'
                      style={{ backgroundColor: "#044BA4" }}
                    />
                    {/* ttm-history-box-border  */}
                    <div
                      className='ttm-history-box-details '
                      style={{ backgroundColor: "#0692D0" }}
                    >
                      <div className='featured-icon'>
                        <div
                          className='ttm-icon ttm-icon_element-size-md'
                          style={{ color: "#044BA4" }}
                        >
                          <IoHomeSharp />
                        </div>
                      </div>
                      <div className='ttm-historybox-title'>
                        <h5 style={{ color: "white" }}>
                          Residential <br /> Rooftops
                        </h5>
                      </div>
                      {/* historybox-title  */}
                      <div
                        className='ttm-historybox-description'
                        style={{ color: "white" }}
                      >
                        {/* description  */}
                        At StarpowerX Solar, we bring the power of the sun to your
                        doorstep with our Residential Rooftop Solar Solutions.
                      </div>
                      <a
                        className='mt-20 ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'
                        style={{ color: "#044BA4" }}
                        href={routes.residentialRooftops}
                      >
                        Read More <i className='ti ti-angle-double-right' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos='flip-left' data-aos-duration='1000'>
                <div className='ttm-box-col-wrapper'>
                  <div className='ttm-history-box-wrapper'>
                    <div className='featured-thumbnail'>
                      {/* featured-thumbnail */}
                      <img
                        id='logo-img'
                        className='img-fluid'
                        alt='logo'
                        src='images/rooftop3.webp'
                      />
                    </div>
                    {/* <div className="ttm-history-box-icon-wrapper">
              
                       
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-size-md">
                             <FaIndustry />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    <div
                      className='ttm-history-box-border'
                      style={{ color: "#044BA4" }}
                    />
                    {/* ttm-history-box-border  */}
                    <div
                      className='ttm-history-box-details '
                      style={{ backgroundColor: "#0692D0" }}
                    >
                      <div className='featured-icon'>
                        <div
                          className='ttm-icon ttm-icon_element-size-md'
                          style={{ color: "#044BA4" }}
                        >
                          <PiBuildingApartmentFill />
                        </div>
                      </div>
                      <div className='ttm-historybox-title'>
                        <h5 style={{ color: "white" }}>
                          Commercial <br /> Rooftops
                        </h5>
                      </div>
                      {/* historybox-title  */}
                      <div
                        className='ttm-historybox-description'
                        style={{ color: "white" }}
                      >
                        {/* description  */}
                        StarpowerX Solar's Commercial Rooftop Solutions: Empowering
                        Businesses with Clean and Efficient Energy,
                      </div>
                      <a
                        className='mt-20 ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'
                        style={{ color: "#044BA4" }}
                        href={routes.commercialRooftops}
                      >
                        Read More <i className='ti ti-angle-double-right' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos='flip-left' data-aos-duration='1000'>
                <div className='ttm-box-col-wrapper'>
                  <div className='ttm-history-box-wrapper'>
                    <div className='featured-thumbnail'>
                      {/* featured-thumbnail */}
                      <img
                        id='logo-img'
                        className='img-fluid'
                        alt='logo'
                        src='images/rooftop2.webp'
                      />
                    </div>
                    {/* <div className="ttm-history-box-icon-wrapper">
              
                       
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-size-md">
                             <FaIndustry />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    <div
                      className='ttm-history-box-border'
                      style={{ backgroundColor: "#044BA4" }}
                    />
                    {/* ttm-history-box-border  */}
                    <div
                      className='ttm-history-box-details '
                      style={{ backgroundColor: "#0692D0" }}
                    >
                      <div className='featured-icon'>
                        <div
                          className='ttm-icon ttm-icon_element-size-md'
                          style={{ color: "#044BA4" }}
                        >
                          <PiSolarRoofBold />
                        </div>
                      </div>
                      <div className='ttm-historybox-title'>
                        <h5 style={{ color: "white" }}>
                          Housing <br /> Society
                        </h5>
                      </div>
                      {/* historybox-title  */}
                      <div
                        className='ttm-historybox-description'
                        style={{ color: "white" }}
                      >
                        {/* description  */}
                        At StarpowerX Solar, we are dedicated to providing
                        cutting-edge Industrial Rooftop Solar
                      </div>
                      <a
                        className='mt-20 ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right'
                        style={{ color: "#044BA4" }}
                        href={routes.housingSociety}
                      >
                        Read More <i className='ti ti-angle-double-right' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>

            {/* <div className="row">
              <div className="mt-5 text-center col-md-12 res-991-mt-30">
                <p className="mb-0">Don’t hesitate, contact us for better help and services. <strong><u><a className="ttm-textcolor-darkgrey"> View more Services</a></u></strong></p>
              </div>
            </div> */}
          </div>
        </section>
        {/* <div>
          <video src={video} controls={true} autoPlay playsInline loop className='w-100 d-block'>
            <source src={video} type="video/mp4" />
          </video>
        </div> */}

        <div>
          <div data-aos='fade-up'>
            <div className='content-inner '>
              <div
                // className='row whySolar'
                style={{ backgroundColor: "white" }}
                className='clearfix row ttm-row  ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
              >
                <div
                  className='col-lg-6 '
                  style={{ backgroundColor: "#ffffff" }}
                  data-aos='fade-right'
                >
                  <div className='grey-border res-991-mt-30 text-left'>
                    <div className='ttm_single_image-wrapper '>
                      <img
                        id='logo-img'
                        className='img-fluid whySolarImg '
                        alt='logo'
                        src='images/grid-solar.jpg'
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div
                  className='col-lg-6 '
                  data-aos='fade-left'
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                    display:'flex', flexDirection:"column",justifyContent:"center",alignContent:"center"
                  }}
                >
                  <div className='text-left' >
                    <h3 className='testimonial-caption' style={{textAlign:"center"}}>
                      Grid-tied Solar system
                    </h3>
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p>
                      A grid-tied solar system, also known as grid solar, is a solar power setup connected directly to the electricity grid. It uses solar panels to convert sunlight into electricity, which is either used to power your home or business or fed back into the grid. When the panels produce excess electricity, the surplus is sent to the grid, often resulting in energy credits (net metering). At night or when solar production is low, the system draws electricity from the grid. Grid-tied systems are cost-effective since they reduce electricity bills without needing expensive batteries for energy storage.
                    </p>
                  </div>
                </div>
                {/* <div className='col-lg-6 my-auto ' style={{ display: "flex", justifyContent: "center", backgroundColor: "#0692D0" }}>
                  <div className='text-left ttm-row'>
                    <h3 className='title whySolarTitle fs-30'>
                      Why Solar Power Companies ?
                    </h3>
                   
                    <ul className='ttm-textcolor-white ttm-list ttm-list-textsize-medium ttm-list-style-icon whySolarContent pt-15'>
                      <li className='mt-3'>
                        <i className='fa fa-arrow-circle-right ttm-textcolor-white' />
                        <span className='ttm-list-li-content ttm-textcolor-white'>
                          1 Year free monitoring
                        </span>
                      </li>
                      <li className='mt-3'>
                        <i className='fa fa-arrow-circle-right ttm-textcolor-white' />
                        <span className='ttm-list-li-content ttm-textcolor-white'>
                          Installation + 1 year free service
                        </span>
                      </li>
                      <li className='mt-3'>
                        <i className='fa fa-arrow-circle-right ttm-textcolor-white' />
                        <span className='ttm-list-li-content ttm-textcolor-white'>
                          PM Suryaghar Subsidy
                        </span>
                      </li>
                      <li className='mt-3'>
                        <i className='fa fa-arrow-circle-right ttm-textcolor-white' />
                        <span className='ttm-list-li-content ttm-textcolor-white'>
                          25 years panel warrenty
                        </span>
                      </li>
                      <li className='mt-3'>
                        <i className='fa fa-arrow-circle-right ttm-textcolor-white' />
                        <span className='ttm-list-li-content ttm-textcolor-white'>
                          Tailor made structure
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row' data-aos='fade-right'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                  <div className='clearfix text-center section-title with-desc'>
                    {/* <div className='title-desc'>Clean, Green Solar Power</div> */}
                    <div className='title-header'>
                      <h2 className='title'>
                      Harnessing Solar Power for a <span className='ttm-textcolor-white'>
                      Sustainable Future?
                        </span>
                      </h2>
                    </div>
                    <div className='title-desc'>
                    At StarpowerX Solar, we specialize in delivering advanced Industrial Rooftop Solar Power solutions that empower industries to utilize solar energy and revolutionize their energy usage. Our unwavering dedication to sustainability and innovation ensures that we provide dependable, efficient, and economical solar solutions, customized to address the unique requirements of each industry.

                    </div>
                  </div>
                  {/* section-title end */}
                  <div className='mt-5 title-header'>
                    <h2 className='title'></h2>
                  </div>
                </div>
              </div>
            </div>
            <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
              <div className='container-xl'>
                <div
                  className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                  style={{ backgroundColor: "#0692D0" }}
                  data-aos='fade-left'
                >
                  <div className='col-md-3'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5> 5 Years free monitoring</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>StarpowerX provides 5 years of free monitoring to ensure your system runs smoothly.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div
                      className='featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white'
                      style={{ backgroundColor: "#0692D0" }}
                    >
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5> Installation + 1 year free service</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>Installation is included with a 1-year free service plan from StarpowerX.</p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Pan-India Connectivity</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>Wide Range of Network Across India.</p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Innovative and Sustainable</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                            Constantly identify new eco-friendly solar solutions
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Dedicated Service Team</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                            Count on our experts to swiftly identify & solve
                            issues
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5> PM Suryaghar Subsidy</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                          Take advantage of the PM Suryaghar Subsidy with StarpowerX, helping you save on solar costs.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Tailor made structure</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                          Get a tailor-made structure designed by StarpowerX to perfectly fit your solar needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Quality Assured Products</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>International Certified Products & Components.</p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5> 25 years panel warrenty</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                          StarpowerX offers a 25-year warranty on solar panels for long-term peace of mind.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className='col-md-3'>
                    {/*  featured-icon-box */}
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                      <div className='featured-content'>
                        {/*  featured-content */}
                        <div className='featured-title'>
                          {/*  featured-title */}
                          <h5>Customer-Centric</h5>
                        </div>
                        <div className='mt-1 featured-desc'>
                          {/*  featured-desc */}
                          <p>
                            Your satisfaction is our priority, providing
                            seamless support.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  
                </div>
              </div>
            </section>
          </div>
        </section>
        <section className='clearfix ttm-row process-section' style={{display: "none"}}>
          <div className='container-xl' data-aos='fade-right'>
            <div className='row'>
              <div className='col-md-12'>
                {/* section title */}
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h5>StarpowerX</h5>
                    {/* <h2 className='title'>
                      What Our Customer <span>Says</span>
                    </h2> */}
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            {/* row end */}
          </div>
        </section>
        <br />
        <br />

        <section className='clearfix ttm-row process-section' style={{display: "none"}}>
          <div className='container-xl' data-aos='fade-up'>
            <div className='row no-gutters mt_100 res-991-mt-0'>
              {/* <div className='col-md-6'>
                <div className='col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-darkgrey ttm-textcolor-white'>
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer'>
                    <div className='ttm-bg-layer-inner' />
                  </div>
                  <div className='layer-content'>
                    <div className='testimonial-slide style1'>
                      <Slider
                        className='row slick_slider'
                        arrows={false}
                        dots={true}
                      >
                        <div className='col-lg-12'>
                          <div className='featured-thumbnail'>
                            <img
                              id='logo-img'
                              className='img-fluid'
                              alt='logo'
                              src={voice1}
                            />
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='featured-thumbnail'>
                            <img
                              id='logo-img'
                              className='img-fluid'
                              alt='logo'
                              src={voice2}
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className='col-md-12'>
                <div
                  className=' ttm-textcolor-white'
                  style={{ backgroundColor: "#0692D0" }}
                >
                  <div className='ttm-col-wrapper-bg-layer ttm-bg-layer'>
                    <div className='ttm-bg-layer-inner' />
                  </div>
                  <div className='layer-content'>
                    <div className='testimonial-slide style1'>
                      <Slider
                        className='row slick_slider'
                        arrows={false}
                        dots={true}
                      >
                        <div className='col-lg-12'>
                          <div className='text-center testimonials'>
                            <div className='testimonial-content'>
                              {/* testimonials-content */}
                              <div className='testimonial-avatar'>
                                <div className='testimonial-img'>
                                  {/* testimonials-img */}
                                  <img
                                    className='img-fluid'
                                    alt='customer'
                                    src="https://via.placeholder.com/1920X850/888888.jpg"
                                  />
                                </div>
                              </div>
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>
                                StarpowerX
                                </h6>
                              </div>
                              <blockquote>
                              StarpowerX's professionalism, clear ideas &
                                implementations,and quality workmanship leave a
                                lasting impression. Their after- sales mechanism
                                excels in regularly monitoring plant generation
                                and promptly resolving issues with site visits.
                              </blockquote>

                              <br />
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>Name</h6>
                                <label style={{color:"white"}}>Position</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='text-center testimonials'>
                            <div className='testimonial-content'>
                              {/* testimonials-content */}
                              <div className='testimonial-avatar'>
                                <div className='testimonial-img'>
                                  {/* testimonials-img */}
                                  <img
                                    className='img-fluid'
                                    alt='customer'
                                   src="https://via.placeholder.com/1920X850/888888.jpg"
                                  />
                                </div>
                              </div>
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>StarpowerX</h6>
                              </div>
                              <blockquote>
                                We have installed StarpowerX Solar for our cold
                                storages at 14 Locations of 3MW Capacity. Their
                                post-sales service is truly awesome. StarpowerX
                                offered us the best deal with topnotch
                                technology, quality, and pricing. We highly
                                recommend StarpowerX for industries.
                              </blockquote>

                              <br />
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>Name</h6>
                                <label style={{color:"white"}}>Position</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='text-center testimonials'>
                            <div className='testimonial-content'>
                              {/* testimonials-content */}
                              <div className='testimonial-avatar'>
                                <div className='testimonial-img'>
                                  {/* testimonials-img */}
                                  <img
                                    className='img-fluid'
                                    alt='customer'
                                   src="https://via.placeholder.com/1920X850/888888.jpg"
                                  />
                                </div>
                              </div>
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>
                                StarpowerX
                                </h6>
                              </div>
                              <blockquote>
                                {" "}
                                StarpowerX has executed a 520Kwp Solar Plant at our
                                Shamirpet Unit, we really appreciate the teams
                                dedication and how quick the execution was
                                completed. The generation of Solar power units
                                is above our expectations.
                              </blockquote>

                              <br />
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>Name</h6>
                                <label style={{color:"white"}}>Position</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='text-center testimonials'>
                            <div className='testimonial-content'>
                              {/* testimonials-content */}
                              <div className='testimonial-avatar'>
                                <div className='testimonial-img'>
                                  {/* testimonials-img */}
                                  <img
                                    className='img-fluid'
                                    alt='customer'
                                    src="https://via.placeholder.com/1920X850/888888.jpg"
                                  />
                                </div>
                              </div>
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>StarpowerX</h6>
                              </div>
                              <blockquote>
                              StarpowerX Installed 15kw ongrid rooftop systems at
                                our association in the month of March, 2019. The
                                system is working very well and we are very
                                happy with the on time service for any of the
                                queries. we can strongly recommend to go for
                                StarpowerX.
                              </blockquote>

                              <br />
                              <div className='testimonial-caption'>
                                {/* testimonials-caption */}
                                <h6>Name</h6>
                                <label style={{color:"white"}}>Position</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="clearfix ttm-row process-section">
          <div className="container-xl">

            <div className="row">
              <div className="col-md-12">
               
                <div className="clearfix text-center section-title with-desc">
                  <div className="title-header">
                    <h5>Awards</h5>
                    <h2 className="title">Award-Winning Excellence: <br />Illuminate Your World with <span> Suntek Solar!</span></h2>

                  </div>
                  <div className="title-desc">
                    <p>Ranked No.1 Solar Company by Telangana State Renewable Energy Development Corporation, TSREDCO (Govt of Telangana) & MNRE (Govt of India), Suntek Energy Systems Private Limited has been recognized for relentless commitment to excellence & Sustainability.</p>

                  </div>
                </div>

              </div>
            </div>
           
          
              <div className='awards' >
                <div className='awardsContent' >
                  <div className='awardsection'>
                    <img id="logo-img" className='awardsImg'  alt="logo" src="images/award1.jpg" />
                  </div>
                  <div className="featured-content">
                    <div className="featured-title" style={{textAlign:"center"}}><h5>The State Leadership Awards <br/> in Telangana 2024</h5></div>
                  </div>
                </div>
                
                <div className='awardsContent' >
                  <div className='awardsection' >
                    <img id="logo-img" className='awardsImg'  alt="logo" src="images/award2.jpg" />
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"  style={{textAlign:"center"}}><h5>Telangana & A.P Annual <br/> Solar Awards 2023</h5></div>
                  </div>
                </div>

              </div>
           
              <div className="col-lg-12 res-991-mt-40 res-991-text-center" style={{textAlign:"center"}}>
                <div className="position-relative res-991-mt-30">
                  
                  <div className="ttm_single_image-wrapper">
                    <img id="logo-img" className="img-fluid" alt="logo" src="images/award-photo.png" />
                  </div>
                </div>
              </div>
              </div>
       
        </section> */}
        <section className='clearfix ttm-row process-section mt_100 res-991-mt-0'>
          <div className='container-xl' data-aos='fade-left'>
            <div className='mb-5 row align-items-center'>
              <div className='col-lg-5 res-991-text-center'>
                <div className='clearfix mb-0 section-title style2'>
                  <div className='mb-0 title-header'>
                    <h5>Best Solar Energy Solutions</h5>
                    <h2 className='title'>
                      Query now for <span>instant answers</span>
                    </h2>
                    <h5 className='mt-5 title'>Head Office</h5>
                    <div className='title-desc'>
                    Sy No 198, Plot No- 6, 1st Floor, Above HDFC Bank, Sriramnagar Colony, Turkayamjal, Telangana 501510.
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5 res-991-text-center'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23533.73429567197!2d78.5883573!3d17.274699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba1d048901473%3A0x36e681f00f6ef3db!2sStarpowerX%20Solar%20Energy!5e1!3m2!1sen!2sin!4v1724392816945!5m2!1sen!2sin'
                  width='600'
                  height='250'
                  style={{ border: 0 }}
                  loading='lazy'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
              {/* <div className="text-right col-lg-2 res-991-text-center res-991-mt-20">
                <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"> Read More </a>
              </div> */}
            </div>
          </div>
        </section>
        <section className='clearfix ttm-row process-section mt_100 res-991-mt-0 d-none' style={{display: "none"}}>

          <div
            className='ttm-bgcolor-skincolor ttm-textcolor-white ttm-right-span'
            style={{ backgroundColor: "#044BA4" }}
          >
            <div className='spacing-1'>
              {/* section title */}
              <div
                className='clearfix section-title with-desc'
                data-aos='fade-up'
              >
                <div className='title-header'>
                  <h5>Upfront Costs and Future Savings</h5>
                  <h2 className='title'>
                  Slash Your <span>Electricity Bills</span>
                  </h2>
                </div>
                <div className='title-desc'>
                  <p>
                  StarpowerX Solar Rooftops provides an innovative solution for sustainable energy, slashing electricity costs by up to 90%. By leveraging advanced solar technology, businesses can realize significant savings and support a greener, more eco-friendly future.

                  </p>
                  {/* <p>
                    <button className='ttm-btn ttm-btn-size-md ttm-textcolor-skincolor ttm-bgcolor-white'>
                      Click here
                    </button>
                  </p> */}
                </div>
              </div>
              {/* section title end */}
              <div className='mt-40 row'>
                <div className='col-12' data-aos='fade-left'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box style2 left-icon icon-align-top'>
                    <div className='title-header'>
                      <h5>StarpowerX's Portfolio Highlights</h5>
                      <h2 className='title'>
                        ISO 9001 CERTIFIED & NSIC ACCREDITATION
                      </h2>
                    </div>
                    <div className='featured-icon'>
                      {/* featured-icon */}
                      <div className='ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md'>
                        <IoIosBusiness />
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        {/* featured title */}
                        <h5>0</h5>
                      </div>
                      <div className='featured-desc'>
                        {/* featured desc */}
                        <p>Years in the Business</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* separator */}
              <div className='separator'>
                <div className='mt-4 mb-4 sep-line' />
              </div>
              {/* separator */}
              <div className='row'>
                <div className='col-12' data-aos='fade-left'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box style2 left-icon icon-align-top'>
                    <div className='featured-icon'>
                      {/* featured-icon */}
                      <div className='ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md'>
                        <MdOutlineCardMembership />
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        {/* featured title */}
                        <h5>0</h5>
                      </div>
                      <div className='featured-desc'>
                        {/* featured desc */}
                        <p>Members in Our Team</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* separator */}
              <div className='separator'>
                <div className='mt-4 mb-4 sep-line' />
              </div>
              {/* separator */}
              <div className='row'>
                <div className='col-12' data-aos='fade-left'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box style2 left-icon icon-align-top'>
                    <div className='featured-icon'>
                      {/* featured-icon */}
                      <div className='ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md'>
                        <MdOutlineSettings />
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        {/* featured title */}
                        <h5>0</h5>
                      </div>
                      <div className='featured-desc'>
                        {/* featured desc */}
                        <p>Current Commercial & Residential Projects</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='separator'>
                <div className='mt-4 mb-4 sep-line' />
              </div>
              <div className='row'>
                <div className='col-12' data-aos='fade-left'>
                  {/* featured-icon-box */}
                  <div className='featured-icon-box style2 left-icon icon-align-top'>
                    <div className='featured-icon'>
                      {/* featured-icon */}
                      <div className='ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md'>
                        <FaSolarPanel />
                      </div>
                    </div>
                    <div className='featured-content'>
                      <div className='featured-title'>
                        {/* featured title */}
                        <h5>0</h5>
                      </div>
                      <div className='featured-desc'>
                        {/* featured desc */}
                        <p>Total Solar Projects</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
          id="pmsubsidy"
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row' data-aos='fade-left'>

              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                  <div className='clearfix text-center section-title with-desc'>

                    <div className='title-header'>
                      <h2 className='title'>
                        Key Features of the <span className='ttm-textcolor-white'>
                          PM Surya Ghar Subsidy
                        </span>
                      </h2>
                    </div>

                  </div>

                  <div className='mt-5 title-header'>
                    <h2 className='title'></h2>
                  </div>
                </div>
              </div>
            </div>
            <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
              <div className='container-xl'>
                <div
                  className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                  style={{ backgroundColor: "#0692D0" }}
                  data-aos='fade-right'
                >
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Substantial Financial Support:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>Households can receive up to ₹78,000 as a subsidy for installing a 3 kW rooftop solar system.
                            The subsidy covers 60% of the cost for systems up to 2 kW and 40% for additional capacity up to 3 kW.
                            Group housing societies can avail ₹18,000 per kW for installations up to 500 kW.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Advanced Technology:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>The program promotes the use of advanced micro inverter technology, making solar PV systems safer, smarter, and more reliable.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Environmental and Economic Benefits:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>By increasing the use of solar power, the program aims to reduce carbon emissions and lower electricity bills for households.
                            It is estimated that the scheme will save the government ₹75,000 crore per year in electricity costs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Boosting Solar Capacity: </h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>The initiative is expected to add 30 GW of solar power through rooftop installations, contributing significantly to India’s goal of reaching 500 GW of renewable energy by 2030.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </section>
        <br />
        <br />
        <br />
        <section
          className='clearfix ttm-row process-section'
          data-aos='flip-up'
        >
          <div className='container-xl'>
            <div className='row'>
              <div className='col-md-12'>
                {/* section title */}
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h5>Frequently Asked Questions</h5>
                    <h2 className='title'>FAQS</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            {/* row end */}
          </div>
        </section>
        <section
          className='clearfix ttm-row service-section position-relative z-1'
          data-aos='flip-down'
        >
          <div className='container-xl'>
            <div
              className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
              style={{ backgroundColor: "#0692D0" }}
            >
              <div
                className='col-md-6'
                data-aos='flip-left'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>What is a solar panel?</h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      A solar panel is a collection of photovoltaic cells designed to absorb the sun’s rays as a source of energy for generating electricity.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-right'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>How solar panels work?</h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      Solar power is harnessed using Solar Photovoltaic (PV) technology that converts sunlight (Solar radiation) into electricity by using semiconductors. 

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-left'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>
                      What is the duration of warranty on solar panels?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      Worried about solar panels warranty? Don't worry, as the panel's come loaded with 25 years of performance warranty!

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-left'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>How to install solar panels at home?                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      There are different ways to install solar panels for homes. First, you have to do homework, such as choosing the installation area, selecting the type of mounting structure, and no. of solar panels.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-right'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>How to calculate solar panel battery and inverter?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      First, you should check your home power consumption per day. Suppose, you have 8 units of consumption every day, then you can install a 1kw solar panel. Because it generates 4-5 units per day and 1400 units yearly.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>

              <div
                className='col-md-6'
                data-aos='flip-left'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>How much solar panels cost?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>The cost of the solar panel depends on the capacity of the solar panel, the technology on which the panel is built, variant or model of the solar PV panel, quality, the manufacturer and the brand. The price of a solar panel usually varies upon the parameters shared along with similar criteria for the other components that build up for the complete solar system.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-right'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>Does making solar panels cause pollution?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      It depends on your perspective. In the production of solar cells metals like mercury and lead are used because of which a little number of oxides of the metals are released in the environment. Over a long period of time, it does not cause pollution but looking only at manufacturing it does on a small scale.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>

              
              <div
                className='col-md-6'
                data-aos='flip-right'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>Which direction should the solar panels face and why?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      Solar panels need to face true south direction for maximum power generation. The reason behind it is that when the sun moves from east and goes to west, it leans towards south direction. Irrespective of which part of the year it is, inclination will always be towards the south. Hence for the optimum harnessing of sunlight, the solar PV panels should ideally face south.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>

              <div
                className='col-md-6'
                data-aos='flip-left'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>What types of buildings is this suitable for?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      It's suitable for all types of buildings. The only prerogative is that there should be a roof on top of it, strong enough to support the structure. Installation also varies depending on whether the roof is slanted or flat. In India, we mostly have flat roofs and the structures are created accordingly. The position of the surrounding elements, such as buildings or trees, which can cause shade on the panels play a crucial role in determining the area on the roof suitable for optimum solar power generation. At times there are obstacles on the roof, such as water tanks, which affects how the structure to support the solar panels will be erected as well.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div
                className='col-md-6'
                data-aos='flip-right'
                data-aos-duration='1000'
              >
                {/*  featured-icon-box */}
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white'>
                  <div className='featured-content'>
                    {/*  featured-content */}
                    <div className='featured-title'>
                      {/*  featured-title */}
                      <h5>Can I use normal cables in a rooftop solar system?
                      </h5>
                    </div>
                    <div className='mt-1 featured-desc'>
                      {/*  featured-desc */}
                      <p>
                      You can't use any regular cable for your solar power system. Specialized cables custom made for residential solar power plants are used to ensure minimal loss of electricity being generated. They are UV protected and strong and long lasting enough to withstand the rigors of nature - be it peak of summers, incessant rains or thunderstorms. Use of any ordinary cable is a big health hazard as well with high chances of electrocution for the people in vicinity of the system. Bad quality wire will not only make your solar power system ineffective but will result in being more expensive as you will end up changing the wires of the entire system if the exposed parts get deteriorated resulting in the risk of electrocution.

                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
          </div>
        </section>
        <br />
        {/* <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row' data-aos='fade-left'>

              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                  <div className='clearfix text-center section-title with-desc'>

                    <div className='title-header'>
                      <h2 className='title'>
                        Key Features of the <span className='ttm-textcolor-white'>
                          PM Surya Ghar Subsidy
                        </span>
                      </h2>
                    </div>

                  </div>

                  <div className='mt-5 title-header'>
                    <h2 className='title'></h2>
                  </div>
                </div>
              </div>
            </div>
            <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
              <div className='container-xl'>
                <div
                  className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                  style={{ backgroundColor: "#0692D0" }}
                  data-aos='fade-right'
                >
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Substantial Financial Support:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>Households can receive up to ₹78,000 as a subsidy for installing a 3 kW rooftop solar system.
                            The subsidy covers 60% of the cost for systems up to 2 kW and 40% for additional capacity up to 3 kW.
                            Group housing societies can avail ₹18,000 per kW for installations up to 500 kW.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Advanced Technology:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>The program promotes the use of advanced micro inverter technology, making solar PV systems safer, smarter, and more reliable.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Environmental and Economic Benefits:</h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>By increasing the use of solar power, the program aims to reduce carbon emissions and lower electricity bills for households.
                            It is estimated that the scheme will save the government ₹75,000 crore per year in electricity costs.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                      <div className='featured-content'>

                        <div className='featured-title'>

                          <h5>Boosting Solar Capacity: </h5>
                        </div>
                        <div className='mt-1 featured-desc'>

                          <p>The initiative is expected to add 30 GW of solar power through rooftop installations, contributing significantly to India’s goal of reaching 500 GW of renewable energy by 2030.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </section> */}

        {/* <iframe src="https://www.w3schools.com" title="W3Schools Free Online Web Tutorials">
</iframe> */}
        {/* <div>
          <div className="title-header">
            <h2 className="title">Our Installations</h2>
          </div>
          <iframe src="https://www.google.com/maps/d/embed?mid=1Xvu32fPmTgEvjvykGEFLl7980-NlKiFI&ehbc=2E312F" width="640" height="480">
          </iframe>
        </div> */}

        <br />
        <br />
        {/* blog-section end */}
      </div>
    );
  }
}

export default Home;
