import React from "react";
import Header from "../layout/Header";
import routes from "../../constants/routes";

function HousingSociety() {
  return (
    <div>
      <Header />
      <section className="ttm-row aboutus-section clearfix">
        <div
          className="position-relative rooftopbg"
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className="auto-container">
          <div
            className="row position-relative justify-content-between align-items-center text-white px-4"
            style={{ zIndex: 2,width:"100%" }}
          >
            <h4 className="display-4 font-weight-bold mb-0 text-white text-center"  style={{ fontSize: "35px" }}>
             Housing Society
            </h4>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center mb-0 text-right text-sm-center "
                style={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  gap: "7px",
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href={routes.home}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </li>
                <span style={{ color: "white" }}>{">"}</span>Housing Society
              </ol>
            </nav>
          </div>
          </div>
        </div>
<br />
<div style={{ padding: "40px" }}>
          <div>
            <div className='content-inner '>
              <div className='row whySolar'>
                <div className='col-lg-6'>
                  <div className=' res-991-mt-30 text-left'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img
                        id='logo-img'
                        className='img-fluid '
                       
                        alt='logo'
                        src='images/housingsociety.png'
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div
                  className='col-lg-6 '
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                     display:'flex', flexDirection:"column",justifyContent:"center",alignContent:"center",
                  }}
                >
                  <div className='text-left' >
                    <h3 className='testimonial-caption'>
                    Harnessing the Power of the Sun for a Brighter Future
                    </h3>
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p >
                    We are committed to creating a sustainable and eco-friendly community. Our solar energy initiative is designed to provide clean, renewable energy to all our residents, ensuring a greener tomorrow.

                    </p>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h4 className='title'>
                    Why Go Solar?

                    </h4>
                  </div>
                </div>
                </div>
                </div>
              </div>
              <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
                <div className='container-xl'>
                  <div
                    className='row justify-content-center'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    {[
                      {
                        img: "images/Significant-Energy-Savings.jpg",
                        title: "Significant Energy Savings",
                        helperText:
                          "Reduce your electricity bills by harnessing the power of the sun. Solar energy is a cost-effective solution that can lead to substantial savings over time.",
                      },
                      {
                        img: "images/Environmental-Impact.jpg",
                        title: "Environmental Impact",
                        helperText:
                          "By switching to solar, we reduce our carbon footprint and contribute to a healthier planet. Solar energy is a clean, renewable resource that helps combat climate change.",
                      },
                      {
                        img: "images/Increased-Property-Value.png",
                        title: "Increased Property Value",
                        helperText:
                          "Homes equipped with solar panels often see an increase in property value. It’s an investment that pays off in more ways than one.",
                      },
                      {
                        img: "images/Energy-Independence.png",
                        title: "Energy Independence",
                        helperText:
                          "Reduce reliance on traditional power sources and protect yourself from rising energy costs. Solar energy provides a stable and predictable energy supply.",
                      },

                      
                    ].map((advantage, index) => (
                      <div
                        key={index}
                        className='col-lg-3 col-md-6 col-sm-12 mb-4'
                        style={{ marginTop: "60px" }}
                      >
                        <div className='d-flex flex-column align-items-center'>
                          <img
                            src={advantage.img}
                            className='rounded-circle border border-warning'
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              padding: "10px",
                            }}
                            alt={advantage.title}
                          />
                          <h5
                            className='font-weight-bold mt-3'
                            style={{ color: "white",textAlign:"center"}}
                          >
                            {advantage.title}
                          </h5>
                          <p style={{textAlign:"center"}} className='text-center'>{advantage.helperText}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            
          </div>
        </section>
        <br />
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h4 className='title'>
                   Our Solar Solutions

                    </h4>
                  </div>
                </div>
                </div>
                </div>
              </div>
              <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
                <div className='container-xl'>
                  <div
                    className='row justify-content-center'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    {[
                      {
                        img: "images/Rooftop-Solar-Panels.jpg",
                        title: "Rooftop Solar Panels",
                        helperText:
                          "Efficiently utilize rooftop space to generate solar power. Our high-quality panels are designed for maximum efficiency and durability.",
                      },
                      {
                        img: "images/Community-Solar-Projects.jpg",
                        title: "Community Solar Projects",
                        helperText:
                          "Participate in shared solar projects that benefit the entire community. Enjoy the advantages of solar energy without the need for individual installations.",
                      },
                      {
                        img: "images/Battery-Storage-Systems.jpg",
                        title: "Battery Storage Systems",
                        helperText:
                          "Store excess solar energy for use during nighttime or cloudy days. Ensure a continuous power supply and maximize your solar investment.",
                      },

          
                    ].map((advantage, index) => (
                      <div
                        key={index}
                        className='col-lg-3 col-md-6 col-sm-12 mb-4'
                        style={{ marginTop: "60px" }}
                      >
                        <div className='d-flex flex-column align-items-center'>
                          <img
                            src={advantage.img}
                            className='rounded-circle border border-warning'
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              padding: "10px",
                            }}
                            alt={advantage.title}
                          />
                          <h5
                            className='font-weight-bold mt-3'
                            style={{ color: "white",textAlign:"center" }}
                          >
                            {advantage.title}
                          </h5>
                          <p className='text-center'>{advantage.helperText}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            
          </div>
        </section>
        <br />
         <div style={{ padding: "40px" }}>
          <div>
            <div className='content-inner '>
              <div className='row whySolar'>
                <div className='col-lg-6'>
                  <div className=' res-991-mt-30 text-left'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img
                        id='logo-img'
                        className='img-fluid  '
                        style={{width:"300px",height:"300px"}}
                        alt='logo'
                        src='images/Easy-Financing-Options.jpg'
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div
                  className='col-lg-6 '
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                     display:'flex', flexDirection:"column",justifyContent:"center",alignContent:"center",
                  }}
                >
                  <div className='text-left' >
                    <h3 className='testimonial-caption'>
                   Easy Financing Options

                    </h3>
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p >
                  We offer flexible financing solutions to make solar energy accessible to everyone. Our partnerships with financial institutions provide affordable loans and EMI options, making it easier for you to join the solar revolution.

                    </p>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
       
        <div style={{ padding: "40px" }}>
          <div>
            <div className='content-inner '>
              <div className='row whySolar'>
                
                <div
                  className='col-lg-6 '
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                     display:'flex', flexDirection:"column",justifyContent:"center",alignContent:"center",
                  }}
                >
                  <div className='text-left' >
                    <h3 className='testimonial-caption'>
                   Join Us in Making a Difference
                    </h3>
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p >
                    Be a part of our green initiative and help us create a sustainable future. Together, we can make a significant impact on our environment and enjoy the benefits of clean, renewable energy.


                    </p>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className=' res-991-mt-30 text-left'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img
                        id='logo-img'
                        className='img-fluid'
                        style={{width:"300px",height:"300px"}}
                        alt='logo'
                        src='images/Join-Us-in-Making-a-Difference.png'
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
  
      </section>
    </div>
  );
}

export default HousingSociety;
