import React from "react";
import Header from "../../layout/Header";
import routes from "../../../constants/routes";

function IndustrialRooftops() {
  return (
    <div>
      <Header />
      <section className='ttm-row aboutus-section clearfix'>
        <div
          className='position-relative rooftopbg'
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
          }}
        >
          <div
            className='position-absolute w-100 h-100'
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className='auto-container'>
            <div
              className='row position-relative justify-content-between align-items-center text-white px-4'
              style={{ zIndex: 2, width: "100%" }}
            >
              <h6
                className='display-4 font-weight-bold mb-0 px-2 text-white text-center'
                style={{ fontSize: "35px" }}
              >
                Industrial Solar Rooftops
              </h6>
              <nav aria-label='breadcrumb'>
                <ol
                  className='breadcrumb justify-content-center mb-0'
                  style={{
                    background: "transparent",
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    gap: "7px",
                  }}
                >
                  <li className='breadcrumb-item'>
                    <a
                      href={routes.home}
                      className='text-white'
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </a>
                  </li>
                  <span style={{ color: "white" }}>{">"}</span> Industrial Solar
                  Rooftops
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* content section 2 */}
        <br />
        <br />
        <div className='col-md-12'>
          <div
            className=' ttm-textcolor-white'
            style={{ backgroundColor: "#0692D0" }}
          >
            <div className='layer-content'>
              <div className='col-lg-12 testimonial-slide style1'>
                <div className='text-center testimonials'>
                  <div className='testimonial-caption'>
                    {/* testimonials-caption */}
                    <h3>Industrial Solar Rooftops</h3>

                    <blockquote>
                      At StarpowerX Solar Systems, we are a trusted name in India for delivering top-quality solar panels. We are excited to introduce our advanced grid-tied solar PV systems, designed to seamlessly integrate with your existing power grid. These systems enable you to efficiently harness solar energy to power your home or business.
                    </blockquote>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container text-center my-5">
          <h3 className="mb-4 font-weight-bold text-center text-sm-left ">
            Industrial Solar Rooftops
          </h3>
          <div className="mt-4 text-center text-sm-left">
            <p>
              Suntek Solar Systems is an established firm in India known for
              providing quality centric solar panels. Here’s introducing our
              cutting edge grid-tied solar PV systems which seamlessly integrate
              with your existing power grid, allowing you to harness the sun's
              energy to power your home or business efficiently. This grid tied
              solar PV Systems are eco-friendly and also cost effective
              alternative to the traditional energy sources. Grid-tied solar PV
              Systems effectively reduces the electricity bills and gives the
              energy independence.
            </p>
            <p>
              These Grid-tied solar PV Systems are well known for their simple
              and simplicity & high end performance. Grid-tied solar PV Systems
              captures the sunlight and convert it into the clean energy,
              meanwhile inverters ensures seamless integration by optimizing
              energy production and thereby reducing power wastage. Our
              grid-tied solar PV systems are tailored to meet your specific
              energy needs, whether you're a homeowner or a business owner.
              Reach out to Suntek now for any queries on Industrial Solar Panel
              and Commercial Solar System.
            </p>
          </div>
        </div> */}

        {/* Advantages Section */}
        <br />
        <br />
        <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
          <div className='container-xl'>
            <div
              className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
              style={{ backgroundColor: "#044BA4" }}

            >
              <div className='col-lg-8 offset-lg-2' style={{ marginTop: "1rem" }}>
                <div className='text-center'>
                  <div className='clearfix text-center section-title with-desc'>

                    <div className='title-header'>
                      <h2 className='title'>
                        Why Choose Our Grid-Tied <span className='ttm-textcolor-white'>
                          Solar PV Systems?
                        </span>
                      </h2>
                    </div>

                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                  <div className='featured-content'>

                    <div className='featured-title'>

                      <h5>Eco-Friendly and Cost-Effective:  </h5>
                    </div>
                    <div className='mt-1 featured-desc'>

                      <p>Our grid-tied solar PV systems not only help reduce your electricity bills but also promote a greener planet by utilizing clean, renewable energy.


                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                  <div className='featured-content'>

                    <div className='featured-title'>

                      <h5>High Performance and Simplicity: </h5>
                    </div>
                    <div className='mt-1 featured-desc'>

                      <p>Renowned for their reliability and high-end performance, our systems capture sunlight and convert it into clean energy. The inverters ensure seamless integration, optimizing energy production and minimizing power wastage.


                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='featured-icon-box left-icon style1 ttm-textcolor-white h-100'>
                  <div className='featured-content'>

                    <div className='featured-title'>

                      <h5>Customized Solutions:  </h5>
                    </div>
                    <div className='mt-1 featured-desc'>

                      <p>Whether you are a homeowner or a business owner, our grid-tied solar PV systems are tailored to meet your specific energy needs.


                      </p>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </section>

        <section className='clearfix ttm-row process-section mt_100 res-991-mt-0'>

          <div
            className='ttm-bgcolor-skincolor ttm-textcolor-white ttm-right-span'
            style={{ backgroundColor: "#0692D0" }}
          >
            <div className='spacing-1'>
              {/* section title */}
              <div
                className='clearfix section-title with-desc'

              >
                <div className='title-header'>

                  <h2 className='title'>
                    Experience <span>Energy Independence </span>
                  </h2>
                </div>
                <div className='title-desc'>
                  <p>

                    By choosing StarpowerX Solar Systems, you are investing in energy independence and sustainability. Our solutions are designed to provide you with reliable and efficient energy, reducing your dependence on traditional power sources.

                  </p>

                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                  <div className='clearfix text-center section-title with-desc'>
                    <div className='title-header'>
                      <h4 className='title'>
                        Empowering Industries with StarpowerX Solar Solutions
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
              <div className='container-xl'>
                <div
                  className='row justify-content-center'
                  style={{ backgroundColor: "#0692D0" }}
                >
                  {[
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/1.jpg",
                      title: "Solar Power, Profit Power",
                      helperText:
                        "Experience a remarkable return on investment with solar energy, achieving financial benefits in less than 2 years.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/4.jpg",
                      title: "Value Added Investment",
                      helperText:
                        "Increase your property’s appeal and attract more buyers and tenants by installing a solar system.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/2.jpg",
                      title: "Low Maintenance",
                      helperText:
                        "Optimize expenses and channel resources towards essential business functions.",
                    },

                    {
                      img: "https://www.sunteksolar.in/images/rooftops/6.jpg",
                      title: "Green Commitment, Strong Brand",
                      helperText:
                        "Boost your brand’s reputation by adopting eco-friendly solar power and sustainable practices.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/3.jpg",
                      title: "Stability in Energy Costs",
                      helperText:
                        "Secure your energy future by generating your own electricity, ensuring cost stability and control.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/7.jpg",
                      title: "Heat Reduction",
                      helperText:
                        "Installing solar power can lower the temperature inside your shed by approximately 4°C",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/8.jpg",
                      title: "Shed or RCC Buildings",
                      helperText:
                        "The lifespan of a shed is significantly extended when it is shielded from direct exposure.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/8.jpg",
                      title: "Shield your Finances from Electricity Costs",
                      helperText:
                        "Protecting Industries from Future Tariff Increases with StarpowerX Solar Solutions.",
                    },
                    {
                      img: "https://www.sunteksolar.in/images/rooftops/5.jpg",
                      title: "Tax Benefits through Depreciation at 40%",
                      helperText:
                        "Maximize financial benefits with annual tax credits and 40% depreciation, making solar investments more viable and profitable.",
                    },
                  ].map((advantage, index) => (
                    <div
                      key={index}
                      className='col-lg-3 col-md-6 col-sm-12 mb-4'
                      style={{ marginTop: "60px" }}
                    >
                      <div className='d-flex flex-column align-items-center'>
                        <img
                          src={advantage.img}
                          className='rounded-circle border border-warning'
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            padding: "10px",
                          }}
                          alt={advantage.title}
                        />
                        <h7
                          className='font-weight-bold mt-3'
                          style={{ textAlign: "center", color: "#044BA4" }}
                        >
                          {advantage.title}
                        </h7>
                        <p style={{ textAlign: "center" }}>
                          {advantage.helperText}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>

        </section>

        {/* <div className="container text-center my-5">
          <h3 className="mb-4 font-weight-bold text-center text-sm-left">
            Benefits for Industries with Suntek Solar
          </h3>
          <div className="row justify-content-center">
            {[
              {
                img: "https://www.sunteksolar.in/images/rooftops/1.jpg",
                title: "Solar Power, Profit Power",
                helperText:
                  " Enjoy a great return on investment with solar energy,boosting your financial gains with less than 2 Years of ROI",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/4.jpg",
                title: "Value Added Investment",
                helperText:
                  "Elevate property values with a solar system, attracting potential buyers and tenants.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/2.jpg",
                title: "Low Maintenance",
                helperText:
                  "Reduce operating costs and allocate resources to critical business areas.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/5.jpg",
                title: "Tax Benefits through Depreciation at 40%",
                helperText:
                  "Leverage tax credits and 40% depreciation every year for a financially viable solar investment.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/6.jpg",
                title: "Green Commitment, Strong Brand",
                helperText:
                  "Enhance your brand image by embracing green solar power and sustainability.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/3.jpg",
                title: "Stability in Energy Costs",
                helperText:
                  "Take control of future energy bills with self-generated electricity, ensuring stability.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/7.jpg",
                title: "Heat Reduction",
                helperText:
                  "Installation of solar power reduces around 4 Degree Celsius heat inside the shed.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/8.jpg",
                title: "Shed or RCC Buildings",
                helperText:
                  "Lifespan of Shed increases as it is not exposed directly.",
              },
              {
                img: "https://www.sunteksolar.in/images/rooftops/8.jpg",
                title: "Shield your Finances from Electricity Costs",
                helperText:
                  "Shielding Industries from future Tariff Hikes with Suntek Solar.",
              },
            ].map((advantage, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-6 col-sm-12 mb-4"
                style={{ marginTop: "60px" }}
              >
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={advantage.img}
                    className="rounded-circle border border-warning"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      padding: "10px",
                    }}
                    alt={advantage.title}
                  />
                  <h7
                    className="font-weight-bold mt-3"
                    style={{ color: "#877655" }}
                  >
                    {advantage.title}
                  </h7>
                  <p>{advantage.helperText}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        {/* EMI Payments Section */}
        <br />
        <br />
        <section
          className='text-white position-relative'
          style={{
            backgroundColor: "#0692D0",

            color: "white",
            padding: "100px 0",
            height: "auto",
            maxHeight: "100%",
          }}
        >
          <div className='container text-left text-sm-center'>
            <div className='row justify-content-center justify-content-sm-between px-4'>
              <div className='col-md-10 col-lg-8'>
                {/* <p
                  className='text-gray text-center text-sm-left'
                  style={{ textDecoration: "underline" }}
                >
                  EMI <i className='bi bi-lightning-fill  text-warning'></i>
                  PAYMENTS
                </p> */}
                <h2 className='font-weight-bold mb-4 text-white  text-center text-sm-left'>
                  Choose: Ongoing Electricity Bills or Short-term EMI Payments?
                </h2>
                <p className='text-center text-sm-left'>
                  Take charge of your electricity expenses for life! With StarpowerX’s solar rooftops, transform your long-term energy costs into affordable short-term EMIs. Embrace clean, renewable energy and enjoy significant savings on your monthly bills. Our customized financing plans simplify your switch to solar power, paving the way for a brighter, more sustainable future for you and your loved ones. Experience the independence of generating your own electricity and secure your energy future with StarpowerX.

                </p>
              </div>
              <img
                src='https://www.sunteksolar.in/images/emi-powerbill.png'
                alt='EMI Icon'
                className='img-fluid'
                style={{ width: "230px", height: "210px" }}
              />
            </div>
          </div>
        </section>

        {/* Industrial Power Systems Section */}
        <section style={{display: "none"}}>
          <div className='container text-center my-5'>
            <h3 className='font-weight-bold mb-4'>Industrial Power Systems</h3>
            <div className='row justify-content-center'>
              {[
                {
                  img: "https://www.sunteksolar.in/images/rooftops/industrial-power-systems/1.jpg",
                  title: "HARIOM 1MW - 1MW - 2MW",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/industrial-power-systems/2.jpg",
                  title: "RAMSE ALUMINUM - 459 KWP",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/industrial-power-systems/3.jpg",
                  title: "VERTICE GLOBAL - 520 KWP",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/industrial-power-systems/4.jpg",
                  title: "STRONTIUM - 1MW",
                },
              ].map((system, index) => (
                <div key={index} className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                  <div className='card'>
                    <img
                      src={system.img}
                      className='card-img-top'
                      alt={system.title}
                      style={{
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <div className='card-body'>
                      <h5 className='card-title font-weight-bold'>
                        {system.title}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Our Industrial Customers Section */}
        <div className='container text-center my-5' style={{display: "none"}}>
          <h3 className='mb-4 font-weight-bold'>Our Industrial Customers</h3>
          <div className='row justify-content-center'>
            {[
              // " Gubba cold storage Pvt Ltd - 3 MW ",
              // " Hariom pipe Industries Ltd - 1 MW + 1MW - 2MW ",
              // " Ace tyres Limited - 1MW + 1 MW - 2MW ",
              // " SpinnMax Tyres Ltd - 1MW ",
              // " Excel Rubber Ltd (Hindhupur)- 1 MW ",
              // " Sriraj Tubes Pvt Ltd - 1 MW ",
              // "Sudhakar PVC – 1MW- Ujjain",
              // " Strontium Ferriten India Ltd- 1MW ",
              // "Mahasai Aluminium Profiles Private Limited - Medchal - 800 KW",
              // " Vertice Global Pvt Ltd- 520KW ",
              // " Ramse Aluminium Products Pvt Ltd- 500kw ",
              // " Integrated Clean Room Technologies Pvt Ltd (ICLEAN) -500 KW",
              // "Exel Rubber Ltd (Pashamailaram) - 486 KW ",
              // "Reactive Metals - Balanagar, Mahabubnagar - 400 kw",
              // "PlastiLami coats Pvt Ltd - 360KW ",
              // "Kumar Enterprises - Nacharam- 330 KW",
              // " Arrow Cables Ltd - 280KW ",
              // "  Balaji Polymers - 180KW ",
              "Innovate Dynamics  ",
              "Elemental Works",
              "Impact Industries",
              "Blueprint Solutions",
              "Pioneer Fabrication",
              "Vertex Innovations",
              "Quantum Crafts",
              "Fusion Technologies",
              "Synergy Designs",
              "Genesis Fabricators",
              "Axion Industries",
              "Stratos Solutions",
              "Optimum Engineering",
              "Nucleus Dynamics",
              "Paramount Industries",
              "Eclipse Manufacturing",
              "Vanguard Works",
              "Spectrum Innovations",

            ].map((installation, index) => (
              <div key={index} className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                <div
                  className='p-3 bg-primary text-white  text-center shadow-md'
                  style={{
                    background: "linear-gradient(80deg,#0692D0 , #044BA4)",
                    borderRadius: "10px",
                  }}
                >
                  <i className='bi bi-check-circle text-warning mr-2'></i>
                  {installation}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default IndustrialRooftops;
