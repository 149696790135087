import React from "react";
import Header from "../layout/Header";
import "../testimonial/videotestimonils.css";
import routes from "../../constants/routes";

const placeholder =[
  {
    image:"/images/gallery/Foto-HD-messico-villanueva-solare-3[1].png",
  },
  {
    image:"/images/gallery/lots-solar-panels-generating-electricity-field (1).jpg",
  },
  {
    image:"/images/gallery/solar-cell-farm-power-station-alternative-energy-from-sun (1).jpg",
  },
  {
    image:"/images/gallery/technician-engineer-checks-maintenance-solar-cell-panels_1150-4263[1].jpg",
  },
]

const testimonialsData = [
  {
    videoSrc: "https://www.youtube.com/embed/fEueXgZjOvM",
    title: "Testimonial 1",
  },
  {
    videoSrc: "https://www.youtube.com/embed/aFEjKrZGmjA",
    title: "Testimonial 2",
  },
  {
    videoSrc: "https://www.youtube.com/embed/MSoVIewwslc",
    title: "Testimonial 3",
  },
  {
    videoSrc: "https://www.youtube.com/embed/uZvPPZO8I6U",
    title: "Testimonial 4",
  },
  {
    videoSrc: "https://www.youtube.com/embed/TuzZ3pN8QmQ",
    title: "Testimonial 5",
  },
  {
    videoSrc: "https://www.youtube.com/embed/7tMkdzXBwlA",
    title: "Testimonial 6",
  },
  {
    videoSrc: "https://www.youtube.com/embed/u8l7wsirozo",
    title: "Testimonial 7",
  },
  {
    videoSrc: "https://www.youtube.com/embed/s40NaUTTfYA",
    title: "Testimonial 8",
  },
  {
    videoSrc: "https://www.youtube.com/embed/izjZ66c-7DI",
    title: "Testimonial 9",
  },
  {
    videoSrc: "https://www.youtube.com/embed/ixA4kimAjnM",
    title: "Testimonial 10",
  },
  {
    videoSrc: "https://www.youtube.com/embed/Zyn95auf3P4",
    title: "Testimonial 11",
  },
  {
    videoSrc: "https://www.youtube.com/embed/cUMPTTgGmUY",
    title: "Testimonial 12",
  },
  {
    videoSrc: "https://www.youtube.com/embed/PkysBhNEXvY",
    title: "Testimonial 13",
  },
  {
    videoSrc: "https://www.youtube.com/embed/k6_VLIpgRIU",
    title: "Testimonial 14",
  },
  {
    videoSrc: "https://www.youtube.com/embed/fj9fr2IRQCQ",
    title: "Testimonial 15",
  },
  {
    videoSrc: "https://www.youtube.com/embed/O0sfVPLiL_o",
    title: "Testimonial 16",
  },
  {
    videoSrc: "https://www.youtube.com/embed/WYTm84GZcQ8",
    title: "Testimonial 17",
  },
  {
    videoSrc: "https://www.youtube.com/embed/eU-Fh7WcC4o",
    title: "Testimonial 18",
  },
  {
    videoSrc: "https://www.youtube.com/embed/V9SRaQwHbxM",
    title: "Testimonial 19",
  },
  {
    videoSrc: "https://www.youtube.com/embed/4I0ChcbGJ-w",
    title: "Testimonial 20",
  },
  {
    videoSrc: "https://www.youtube.com/embed/LB1Ix3JkJss",
    title: "Testimonial 21",
  },
  {
    videoSrc: "https://www.youtube.com/embed/KRNuVEflpMQ",
    title: "Testimonial 22",
  },
  {
    videoSrc: "https://www.youtube.com/embed/zlCKP85zgAk",
    title: "Testimonial 23",
  },
  {
    videoSrc: "https://www.youtube.com/embed/JtacOLp_rr0",
    title: "Testimonial 24",
  },
  {
    videoSrc: "https://www.youtube.com/embed/7hZ7_vraTXc",
    title: "Testimonial 25",
  },
  {
    videoSrc: "https://www.youtube.com/embed/sVkrQWas8VE",
    title: "Testimonial 26",
  },
  {
    videoSrc: "https://www.youtube.com/embed/OtJ6er7qiF0",
    title: "Testimonial 27",
  },
  {
    videoSrc: "https://www.youtube.com/embed/XoGobdW_UhA",
    title: "Testimonial 28",
  },
  {
    videoSrc: "https://www.youtube.com/embed/4J6kZiA5stY",
    title: "Testimonial 29",
  },
  {
    videoSrc: "https://www.youtube.com/embed/BbHDNsTvdTQ",
    title: "Testimonial 30",
  },
  {
    videoSrc: "https://www.youtube.com/embed/LrK7MObTg3w",
    title: "Testimonial 31",
  },
  {
    videoSrc: "https://www.youtube.com/embed/gxAA7Y1HZfI",
    title: "Testimonial 32",
  },
  {
    videoSrc: "https://www.youtube.com/embed/n0gg82X1S9k",
    title: "Testimonial 33",
  },
];

const ProjectSection = () => {
  return (
    <>
      <Header />
      {/* Header Section with Background Image */}
      <div
        className="position-relative mb-4"
        style={{
          backgroundImage:
            "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          padding: "100px 0",
          marginTop: "100px",
        }}
      >
        <div
          className="position-absolute w-100 h-100"
          style={{
            top: 0,
            left: 0,
            backgroundColor: "#233434",
            zIndex: 1,
            opacity: "0.8",
          }}
        ></div>

        {/* Content header section */}
        <div className="auto-container">
          <div
            className="row position-relative justify-content-between align-items-center text-white px-4"
            style={{ zIndex: 2, width: "100%" }}
          >
            <h6
              className="display-4 font-weight-bold mb-0 text-white px-4 text-center"
              style={{ fontSize: "35px" }}
            >
              Gallery
            </h6>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center mb-0"
                style={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  gap: "7px",
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href={routes.home}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </li>
                <span style={{ color: "white" }}>{">"}</span>
                Gallery
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="col-12 text-center ">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          {placeholder.map((item, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card">
                <div className="embed-responsive embed-responsive-16by9">
                <img
                          id='logo-img'
                           className="embed-responsive-item mt-[10px]"
                          alt='logo'
                          src={item.image}
                        />
                  {/* <iframe
                    className="embed-responsive-item mt-[10px]"
                    style={{objectFit:"contain"}}
                    src={testimonial.videoSrc}
                    allowFullScreen
                    title={testimonial.title}
                  ></iframe> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProjectSection;
