import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "../../constants/routes";

export class Menu extends Component {
  render() {
    return (
      <nav id='menu' role='navigation' className='menu'>
        <ul>
          <li className='menu-hasdropdown'>
            <a href={routes.home}>Home</a>
          </li>
          <li className='menu-hasdropdown'>
            <a href={routes.aboutUs}>About Us</a>
          </li>
          <li className='menu-hasdropdown'>
            <a>Products</a>
            <ul className='menu-dropdown'>
              <li className='menu-hasdropdown menu-hasflyout'>
                <a href={routes.solarRooftop}>Solar Rooftops</a>
                <ul className='menu-dropdown'>
                  <li>
                    <a href={routes.residentialRooftops}>
                      Residential Rooftops
                    </a>
                  </li>
                  <li>
                    <a href={routes.industrialRooftops}>Industrial Rooftops</a>
                  </li>
                  <li>
                    <a href={routes.commercialRooftops}>Commercial Rooftops</a>
                  </li>
                  <li>
                    <a href={routes.housingSociety}>Housing Society</a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href={routes.solarWaterHeaters}>Solar Water Heaters</a>
              </li> */}
            </ul>
          </li>
          {/* <li className='menu-hasdropdown'>
            <a href={routes.team}>Team</a>
          </li> */}
          <li className='menu-hasdropdown'>
            <a href={`${routes.home}#pmsubsidy`}>PM Subsidy</a>
          </li>
          <li className='menu-hasdropdown'>
            <a href={routes.GetaQuota}>Get A Quote</a>
          </li>
          {/* <li className='menu-hasdropdown'>
            <a>Client Stories</a>
            <ul className='menu-dropdown'>
              <li>
                <a href={routes.testimonials}>Testimonials</a>
              </li>
              <li>
                <a href={routes.videoTestimonials}>Video Testimonials</a>
              </li>
            </ul>
          </li> */}
          {/* <li className='menu-hasdropdown'>
            <a href={routes.financing}>Financing</a>
          </li> */}
          <li className='menu-hasdropdown'>
            <a>Gallery</a>
            <ul className='menu-dropdown'>
              <li>
                <a href={routes.projects}>Images</a>
              </li>
              {/* <li>
                <a href={routes.awards}>Awards</a>
              </li>
              <li>
                <a href={routes.events}>Events</a>
              </li> */}
            </ul>
          </li>
          <li className='menu-hasdropdown'>
            <a href={routes.contactUs}>Contact Us</a>
            {/* <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/blog'}>Blog Classic</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/blog_grid'}>Blog Grid View</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/blog_top_image'}>Blog Top Image</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/blog_left_image'}>Blog Left Image</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/single_blog'}>Blog Single View</a></li>
                        </ul> */}
          </li>
          {/* <li className='menu-hasdropdown'>
            <a href={routes.educateChild}>Educate Child</a>
          </li> */}
        </ul>
      </nav>
    );
  }
}
