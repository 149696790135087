import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';

export class Single_style1 extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-row aboutus-section clearfix">
                    <div
                        className="position-relative"
                        style={{
                            backgroundImage:
                                "url('https://www.sunteksolar.in/images/inner-bg-1.jpg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            color: "white",
                            padding: "100px 0",
                        }}
                    >
                        <div
                            className="position-absolute w-100 h-100"
                            style={{
                                top: 0,
                                left: 0,
                                backgroundColor: "#233434",
                                zIndex: 1,
                                opacity: "0.8",
                            }}
                        ></div>

                        {/* Content */}
                        <div
                            className="container position-relative d-flex row  justify-content-between align-items-center text-white"
                            style={{ zIndex: 2 }}
                        >
                            <h1 className="display-4 font-weight-bold p-4 mb-0 text-white ">
                                Awards
                            </h1>
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb justify-content-center mb-0 "
                                    style={{
                                        background: "transparent",
                                        padding: 0,
                                        margin: 0,
                                        listStyle: "none",
                                        gap: "7px",
                                    }}
                                >
                                    <li className="breadcrumb-item">
                                        <a
                                            href="#"
                                            className="text-white"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <span style={{ color: "white" }}>{">"}</span> Awards
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <section className="ttm-row project-single-section clearfix"
                style={{
                    marginTop:"-4rem"
                }}
                >
                    <div className="container-xl align-items-center px-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-heading">
                                    <h1 className="title">Awards</h1>
                                </div>{/* /.page-title-captions */}
                                <h4>Award-Winning Excellence:<br />
                                    Illuminate Your World with Suntek Solar!</h4>
                                <p>Ranked No.1 Solar Company by Telangana State Renewable Energy Development Corporation, TSREDCO (Govt of Telangana) & MNRE (Govt of India), Suntek Energy Systems Private Limited has been recognized for relentless commitment to excellence & Sustainability. With 24+ prestigious awards to Suntek , our solar solutions shine bright, empowering homes, industries, and businesses with sustainable power. At Suntek, customer satisfaction is at the core of our mission. Aftersales support is our priority - we daily monitor system reports and promptly send weekly updates to our customers. In the rare event of any issue, our dedicated team directly visits you to swiftly solve the problem.</p>
                            </div>
                            <div className="row no-gutters mt-4">
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                    <img id="logo-img" className="img-fluid" alt="logo" src="images/award2.jpg" />
                                    <div className='solar'>
                                        <div className=' container-xl'>
                                            <div className='carousel-caption-inner banner1'>
                                                {/* <p className='text'>Industrial Solar Rooftops</p> */}
                                                <h2 className='ttm-textcolor-black flipInY'>
                                                    The State Leadership Awards in <strong className='ttm-textcolor-black '>
                                                        Telangana 2024
                                                    </strong>
                                                </h2>
                                                <p>
                                                    <button className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'>
                                                        CLICK HERE
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                    <img id="logo-img" className="img-fluid" alt="logo" src="images/award1.jpg" />
                                    <div className='solar'>
                                        <div className=' container-xl'>
                                            <div className='carousel-caption-inner banner1'>

                                                <h2 className='ttm-textcolor-black flipInY'>
                                                    Telangana & A.P Annual <strong className='ttm-textcolor-black '>
                                                        Solar Awards 2023
                                                    </strong>
                                                </h2>
                                                <p className='text'>Suntek awarded Best EPC Company of the Year at Telangana & A.P Annual Solar Awards 2023</p>
                                                <p>
                                                    <button className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'>
                                                        CLICK HERE
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                    <img id="logo-img" className="img-fluid" alt="logo" src="images/award3.jpg" />
                                    <div className='solar'>
                                        <div className=' container-xl'>
                                            <div className='carousel-caption-inner banner1'>

                                                <h2 className='ttm-textcolor-black flipInY'>
                                                    TSREDCO <strong className='ttm-textcolor-black '>
                                                        Awards
                                                    </strong>
                                                </h2>
                                                <p className='text'>Suntek, honored as the No.1Award Winning Solar Company by TSREDCO</p>
                                                <p>
                                                    <button className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'>
                                                        CLICK HERE
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                    <img id="logo-img" className="img-fluid" alt="logo" src="images/award3.jpg" />
                                    <div className='solar'>
                                        <div className=' container-xl'>
                                            <div className='carousel-caption-inner banner1'>

                                                <h2 className='ttm-textcolor-black flipInY'>
                                                    All <strong className='ttm-textcolor-black '>
                                                        Awards
                                                    </strong>
                                                </h2>
                                                <p className='text'>Celebrated with numerous awards, recognitions, and accolades for excellence</p>
                                                <p>
                                                    <button className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'>
                                                        CLICK HERE
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* separator */}
                            <div className="col-12">
                                <div className="separator">
                                    <div className="sep-line mt-35 mb-35" />
                                </div>
                            </div>
                            {/* separator */}
                        </div>{/* row end*/}
                        {/* row */}
                        <div className="row ttm-pf-single-related-wrapper ">
                            <div className="col-12">
                                <h2 className="mb-30">Award Videos </h2>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/PVLN5lVVFis" title="SUNTEK wins the prestigious award as “The BEST SOLAR ENERGY Company in Telangana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/PVLN5lVVFis" title="SUNTEK wins the prestigious award as “The BEST SOLAR ENERGY Company in Telangana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/Av9IE7M3BCc" title="Suntek featured on HMTV Live for receiving an award for &quot;Best Solar Brand in Telengana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/8FicQWSctwc" title="Suntek gets featured on #10TV for receiving an award for &quot;Best Solar Brand in Telengana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/CL_j1UHTqzI" title="Suntek featured on #V6 News for receiving an award for &quot;Best Solar Brand in Telengana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    <iframe width="356" height="250" src="https://www.youtube.com/embed/gIc_h7tAO-w" title="Suntek featured on #MahaaNews for receiving an award for &quot;Best Solar Brand in Telangana&quot; by TSREDCO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>{/* row end*/}
                    </div>
                </section>
            </div>
        )
    }
}


export default Single_style1;