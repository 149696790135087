import React from "react";
import Header from "../layout/Header";
import './event.css';

const EventSection = () => {
    return (
        <>      
        <Header />
        {/* Header Section with Background Image */}
        <div
          className="position-relative"
          style={{
            backgroundImage:
              "url('https://www.sunteksolar.in/images/inner-bg-1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
              marginTop:"130px"
          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className="auto-container ">
          <div
            className="row position-relative justify-content-between align-items-center text-white px-4"
            style={{ zIndex: 2,width:"100%" }}
          >
            <h6
              className="display-4 font-weight-bold mb-0 px-2 text-white px-4 text-center"
              style={{ fontSize: "35px" }}
            >
             Events
            </h6>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center mb-0"
                style={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  gap: "7px",
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="#"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </li>
                <span style={{ color: "white" }}>{">"}</span> Events
              </ol>
            </nav>
          </div>
          </div>
        </div>

            {/* Main Content */}
            <div className="event-container align-items-center d-flex justify-content-center">
                <div className="event-item align-items-center">
                    <h1 className='mb-0 text-black  text-center'>Events</h1>
                    <br />
                    <img
                        src="https://www.sunteksolar.in/images/gallery/thumbnails/1.jpg"
                        alt="T-HUB"
                        className="event-image"
                    />
                    <div className="event-content">
                        <h3>T-HUB</h3>
                        <a href="/gallery" className="btn btn-warning">CLICK HERE</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventSection;
