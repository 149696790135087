import React from 'react';
import './testimonial.css';
import Header from "../layout/Header";


const testimonialsData = [
    {
        name: "Roopesh Golas",
        title: "MD, HARIOM PIPE INDUSTRIES LTD",
        message: "Suntek's professionalism, clear ideas & implementations, and quality workmanship leave a lasting impression. Their after-sales mechanism excels in regularly monitoring plant generation and promptly resolving issues with site visits.",
        image: "https://www.sunteksolar.in/images/testimonials/3.jpg"
    },
    {
        name: "Kiran Gubba",
        title: "CEO, Gubba Cold Storages",
        message: "We have installed Suntek Solar for our cold storages at 14 Locations of 3MW Capacity. Their post-sales service is truly awesome. Suntek offered us the best deal with topnotch technology, quality, and pricing. We highly recommend Suntek for industries.",
        image: "https://www.sunteksolar.in/images/testimonials/1.jpg"
    },
    {
        name: "Purushotham Pabba",
        title: "Director - Vertice Global",
        message: "Suntek has executed a 520Kwp Solar Plant at our Shamirpet Unit, we really appreciate the teams dedication and how quick the execution was completed. The generation of Solar power units is above our expectations.",
        image: "https://www.sunteksolar.in/images/testimonials/2.jpg"
    },
    {
        name: "Mr. Ajay Kumar Garu",
        title: "TSGENCO director - Civil",
        message: "Suntek Installed 15kw ongrid rooftop systems at our association in the month of March, 2019. The system is working very well and we are very happy with the on time service for any of the queries. we can strongly recommend to go for Suntek.",
        image: "https://www.sunteksolar.in/images/testimonials/4.jpg"
    },
    {
        name: "Varun Naidu",
        message: "Suntek energy systems have installed our solar panels for our home. And we’ve had no issues over the last 4 years. It is a joy to know that not only is our home and home equipment powered by clean energy. Suntek team have been proactive and made installation & operations easy. I’ve recommended them to several of our friends and will continue to do so.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Ajit Datta",
        message: "Installation of solar plant went very smooth. Right from design till implementation the team showed great support and commitment. Suntek team aces at quality, commitment and communication. Will recommend Four solar for everyone who loves to install solar rooftop with NO headache, the team takes care of everything.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Rahul Pandey",
        message: "One of the best experiences I had working with a company in India especially after returning from US. Amazing team assembled together who are very professional, prompt, with great attention to detail and commitment to service. Highly recommend suntek energy systems to anyone looking into solar services.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Prasad Remella",
        message: "For the last 3 to 4 years, I am in search of a good service provider to install rooftop solar system for my individual house. I chose Suntech Energy Systems Pvt Ltd. They installed 4 KVA under Central Government subsidy scheme. Collected net of subsidy from the total cost though they have not yet received subsidy. They helped me to solve long pending application with TSSPDCL for conversion of single phase to 3 phase. All the staff are polite and knowledgeable. They gave excellent service. They are Top solar company in Hyderabad.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Vidya shankar",
        title: "House Owner",
        message: "I have installed grid solar system in my house on 28.04.2021 and proper utilisation is there and more useful to generate the power and supply to ele department more over on an average ele bill amount has came down as netting is taking place on account of power generation. The utility of the solar system is good and encouraged by the government by providing subsidy for installation. They are the best Residential rooftop solar panels in Hyderabad.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Archana",
        message: "Suntek solar company installed 3 Kva solar plants on our rooftop. The whole process from inquiry to installation was smooth and professional.The paperwork was simple and easy. Before installation, they surveyed our rooftop and suggested the ideal location to capture maximum sunlight. The whole installation and wiring was done very neatly. Post-installation also they did good follow-up and would respond to our queries very quickly. Since 1 year we have been getting zero electricity bills. They also installed an app on our phone, which helps us to monitor daily electricity generation. We are so thankful to Suntek Energy Systems. Highly Recommended. it's a Top solar Rooftop company in Hyderabad.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Supriya Priya",
        message: "Suntek has installed Residencial solar panels for our home. And we’ve had no issues over the last 4 years. It is a joy to know that not only is our home and home equipment powered by clean energy, but now our car is also charged via solar power generation. Four solar teams have been proactive and made installation & operations easy. I’ve recommended Suntek to several of our friends. Suntek is Best Residential Solar Company in Hyderabad.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Puri Jagannath Temple",
        title: "Jubliee hills",
        message: "Solar Rooftop ongrid systems is the best concept which the renewable energy dept has introduced in our state, we personally installed these systems and got benefitted with least electricity bill. We Chosen Suntek amongst the top company quotes we received as the best and they proved to be the best. Good work Suntek team...",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Mr.Janaiah Garu",
        title: "VC and Managing Director, TSREDCO",
        message: "Highly recommend Suntek Company as the best company in the field of renewable energy providing services in solar. Under domestic category installed 2kw capacity systems over my roof, and I am generating the power of my own and saving the nature from pollution.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Mr.Amarender Reddy Garu",
        title: "Project Director, TSREDCO",
        message: "I personally know the Suntek as the best Solar company since 8 years, installed 2 kw system and am totally happy with their quality service and technology they used. They extend full cooperation from stage of documentation, till the end of synchronization and also installing the app and checking the updates with production of units.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Mr. Kiran Kumar Reddy Garu",
        title: "Ex-Chief Minister",
        message: "Suntek is very good in providing their extended service to customers till the complete process is done. Well trained and experienced Service and sales team. Suntek.... all the best...",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Mr. Shailendra Kumar Joshi Garu",
        title: "Chief Secretary, Telangana",
        message: "Suntek has personally taken care for the complete process of installation of solar ongrid rooftops at my residence. Very much satisfied. Good Job.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "Pyramid Spiritual Trust",
        title: "Kadthal",
        message: "Suntek Company installed 25kw system at our premises, which is working very good and we are getting less power bill than earlier. We have got subsidy amount on it which government is offered. Their co-operation is very good and team is very hardworking... all the very best.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "AV INFO PRIDE",
        title: "Boduppal",
        message: "We are saving the electricity bill upto 70% every month after the installing the 100kw ongrid rooftops in our venture, earlier we use to pay heavy bills, we have chosen Suntek as best service provider for this , as they use best technology and maintain quality in rendering services, we were also benefitted with subsidy associated with the system.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "R.S. Brothers (P.Satynarayana, P.Venkateshwarlu, Rajamouli, Prasad rao)",
        message: "We came to know about Suntek from our relatives when we are in our discussion about rooftop installations for our sites. So approached Company’s Managing director Mr. Bhavani Suresh, who has given lot of cooperation for this, till the total installation process is done, suntek is very good and system is working efficiently.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "VJIT (Vignan Jyoti Institute of Technology) (Bachupally)",
        title: "Vignan Jyoti Institute of Technology, Bachupally",
        message: "In cooperation with the Suntek Company we have installed 180 kw capacity Solar ongrid systems at our campus. The team has shown excellent performance in the process and we are very happy with the Suntek, service wise also very good.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    },
    {
        name: "LB Reddy (President at Emerald Appartment)",
        title: "President at Emerald Apartment",
        message: "We sorted Suntek In cooperation with the Suntek Company we have installed 180 kw capacity Solar ongrid systems at our campus. The team has shown excellent performance in the process and we are very happy with the Suntek, service wise also very good. the 15 quotes we received and finalized it as the best, we received the best quality material, and advantages with solar is investment can be recovered in short span and eco-friendly, their cooperation in providing the service is very good.",
        image: "https://www.sunteksolar.in/images/testimonials/test-profile.jpg"
    }
];

const Testimonials = () => {
    return (
        <>      
        <Header />
        {/* Header Section with Background Image */}
        <div style={{marginTop: '95px'}} className='contact-header text-center text-white'>
                <div className='header-overlay d-flex justify-content-between align-items-center'>
                    <h1 className='mb-0 text-white'>Testimonials</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb mb-0'>
                            <li className='breadcrumb-item'>
                                <a href='/' className='text-white'>Home</a>
                            </li>
                            <li className='breadcrumb-item active text-white' aria-current='page'>
                                Testimonials
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Testimonials section */}
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 text-center mt-[10px]">
                        <h2>Testimonials</h2>
                    </div>
                </div>
                <div className="row mt-4">
                    {testimonialsData.map((testimonial, index) => (
                        <div key={index} className="col-md-6 mb-4">
                            <div className="testimonial-card p-3">
                                <div className="testimonial-image mb-3">
                                    <img src={testimonial.image} alt={testimonial.name} />
                                </div>
                                <p>{testimonial.message}</p>
                                <h5>{testimonial.name}</h5>
                                {testimonial.title && <small>{testimonial.title}</small>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Testimonials;
