import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';

export class AwardsGallery4 extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <br />
                <div className="ttm-row aboutus-section clearfix">
                    <div
                        className="position-relative"
                        style={{
                            backgroundImage:
                                "url('https://www.sunteksolar.in/images/inner-bg-1.jpg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            color: "white",
                            padding: "100px 0",
                        }}
                    >
                        <div
                            className="position-absolute w-100 h-100"
                            style={{
                                top: 0,
                                left: 0,
                                backgroundColor: "#233434",
                                zIndex: 1,
                                opacity: "0.8",
                            }}
                        ></div>

                        {/* Content */}
                        <div
                            className="container position-relative d-flex row  justify-content-between align-items-center text-white"
                            style={{ zIndex: 2 }}
                        >
                            <h1 className="display-4 font-weight-bold p-4 mb-0 text-white ">
                                Awards
                            </h1>
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb justify-content-center mb-0 "
                                    style={{
                                        background: "transparent",
                                        padding: 0,
                                        margin: 0,
                                        listStyle: "none",
                                        gap: "7px",
                                    }}
                                >
                                    <li className="breadcrumb-item">
                                        <a
                                            href="#"
                                            className="text-white"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <span style={{ color: "white" }}>{">"}</span> Awards
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <section className="ttm-row project-single-section clearfix"
                style={{
                    marginTop:"-4rem"
                }}
                >
                    <div className="container-xl">
                        {/* row */}
                        <div className="page-title-heading">
                                    <h1 className="title">All Awards</h1>
                                </div>{/* /.page-title-captions */}
                        <div className="row ttm-pf-single-related-wrapper ">
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img1.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img2.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img3.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img4.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img5.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img6.jpg" />
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/award4img7.jpg" />
                                </div>
                            </div>
                            
                        </div>{/* row end*/}
                    </div>
                </section>
            </div>
        )
    }
}


export default AwardsGallery4;