import React from "react";
import Header from "../layout/Header";
import "./Carrers.css";

const CareersPage = () => {
    return (
        <>      
      <Header />
      {/* Header Section with Background Image */}
      <div style={{marginTop: '95px'}} className='contact-header text-center text-white'>
            
                <div className='header-overlay d-flex justify-content-between align-items-center'>
                    <h1 className='mb-0 text-white'>Careers</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb mb-0'>
                            <li className='breadcrumb-item'>
                                <a href='/' className='text-white'>Home</a>
                            </li>
                            <li className='breadcrumb-item active text-white' aria-current='page'>
                                Careers
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/* Form Section */}
            <div className="careers-container">
                <div className="container">
                    <h1>Careers</h1>
                    <h3 className="text-left mb-5">Send Your CV</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <form className="form-container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label htmlFor="email" className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="email" placeholder="Email ID" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea className="form-control" id="message" rows="5" placeholder="Enter Message"></textarea>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="resume" className="form-label">Upload Resume</label>
                                    <input type="file" className="form-control-file" id="resume" />
                                </div>
                                <button type="submit" className="btn btn-primary w-100">Submit</button>
                            </form>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <img src="https://www.sunteksolar.in/images/careers.png" alt="Helping Hands" className="side-image" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CareersPage;
