import React from "react";
import Header from "../../layout/Header";
import routes from "../../../constants/routes";

function SolarRooftop() {
  return (
    <div>
      <Header />
      <section className="ttm-row aboutus-section clearfix">
        <div
          className="position-relative rooftopbg"
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className="auto-container">
          <div
            className="row position-relative justify-content-between align-items-center text-white px-4"
            style={{ zIndex: 2,width:"100%" }}
          >
            <h4 className="display-4 font-weight-bold mb-0 text-white text-center"  style={{ fontSize: "35px" }}>
              Solar Rooftops
            </h4>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center mb-0 text-right text-sm-center "
                style={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  gap: "7px",
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href={routes.home}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </li>
                <span style={{ color: "white" }}>{">"}</span> Solar Rooftops
              </ol>
            </nav>
          </div>
          </div>
        </div>
        <p
          className="text-center  mt-4"
          style={{ textDecoration: "underline", textAlign: "center" }}
        >
          Choose Your Product
        </p>
        {/* Content Section 1 */}
        
        <div className='col-md-12'>
          <div
            className=' ttm-textcolor-white'
            style={{ backgroundColor: "#0692D0" }}
          >
            <div className='layer-content'>
              <div className='col-lg-12 testimonial-slide style1'>
                <div className='text-center testimonials'>
                  <div className='testimonial-caption'>
                    {/* testimonials-caption */}
                    <h4>Solar Rooftops</h4>

                    <blockquote>
                    StarpowerX Solar has proudly empowered over 20,000 satisfied customers, specializing in Industrial and Commercial Rooftop Solar Power systems. Our brand has swiftly gained recognition across India, becoming the go-to choice for major organizations, government bodies, CSR projects, and various industries.

                    </blockquote>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content Section 1 */}
        <div class="container text-center my-5">
          <div
            className="d-flex justify-content-center align-items-center text-center row mb-4  px-4"
            style={{ gap: "60px" }}
          >
            <div
              className="shadow-lg d-flex justify-content-center  text-center row "
              style={{ width: "300px", height: "500px", position: "relative",backgroundColor: "#0692D0" }}
            >
              <div className="col text-center">
                <h6 className="mt-4 text-white" style={{ color: "#394f4f" }}>
                  Residential Solar Rooftops
                </h6>
                <img
                  src="https://www.sunteksolar.in/images/icons/residential-solar.png"
                  alt="img1"
                  style={{
                    borderRadius: "50%",
                    width: "200px",
                    height: "200px",
                  }}
                />
                <p className="mt-3 text-white mb-4">
                  At StarpowerX Solar, we bring the power of the sun to your
                  doorstep with our Residential Rooftop Solar Solutions. Our
                  mission is to empower homeowners with clean and sustainable
                  energy..
                </p>
                <button
                  className=" text-white p-2 mb-5 "
                  style={{
                    width: "50%",
                    border: "none",
                    background: "#044BA4",
                  }}
                >
                <a style={{color:"white"}}  href={routes.residentialRooftops}>  VIEW MORE</a>
                </button>
              </div>
            </div>
            <div
              className="shadow-lg d-flex justify-content-center  text-center row "
              style={{ width: "300px", height: "500px", position: "relative",backgroundColor: "#0692D0" }}
            >
              <div className="col text-center">
                <h6 className="mt-4 text-white" style={{ color: "#394f4f" }}>
                  Industrial Solar Rooftops
                </h6>
                <img
                  src="https://www.sunteksolar.in/images/icons/ind-solar.png"
                  alt="img1"
                  style={{
                    borderRadius: "50%",
                    width: "200px",
                    height: "200px",
                  }}
                />
                <p className="mt-3 text-white mb-4">
                  At StarpowerX Solar, we are dedicated to providing cutting-edge
                  Industrial Rooftop Solar Power solutions that enable
                  industries to harness the power of the sun and transform their
                  energy consumption..
                </p>
                <button
                  className=" text-white p-2 mb-5 "
                  style={{
                    width: "50%",
                    border: "none",
                    background: "#044BA4",
                  }}
                >
                 <a style={{color:"white"}} href={routes.industrialRooftops}>  VIEW MORE</a>
                </button>
              </div>
            </div>
            <div
              className="shadow-lg d-flex justify-content-center  text-center row "
              style={{ width: "300px", height: "500px", position: "relative",backgroundColor: "#0692D0" }}
            >
              <div className="col text-center">
                <h6 className="mt-4 text-white" style={{ color: "#394f4f" }}>
                  Commercial Solar Rooftops
                </h6>
                <img
                  src="https://www.sunteksolar.in/images/icons/comm-solar.png"
                  alt="img1"
                  style={{
                    borderRadius: "50%",
                    width: "200px",
                    height: "200px",
                  }}
                />
                <p className="mt-3 text-white mb-4">
                StarpowerX Solar's Commercial Rooftop Solutions: Empowering
                  Businesses with Clean and Efficient Energy At StarpowerX Solar, we
                  are dedicated to providing innovative and reliable Commercial
                  Rooftop Solar..
                </p>
                <button
                  className=" text-white p-2 mb-3 "
                  style={{
                    width: "50%",
                    border: "none",
                    background: "#044BA4",
                  }}
                >
                  <a style={{color:"white"}} href={routes.commercialRooftops}>  VIEW MORE</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SolarRooftop;
