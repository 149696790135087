import React from "react";
import Header from "../../layout/Header";
import { FaIndustry } from "react-icons/fa6";
import "./rooftop.css";
import routes from "../../../constants/routes";

function ResidentialRooftops() {
  return (
    <div>
      <Header />
      <section className='ttm-row aboutus-section clearfix'>
        <div
          className='position-relative rooftopbg'
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
          }}
        >
          <div
            className='position-absolute w-100 h-100'
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className='auto-container'>
            <div
              className='row position-relative justify-content-between align-items-center text-white px-4'
              style={{ zIndex: 2, width: "100%" }}
            >
              <h6
                className='display-4 font-weight-bold mb-0 px-2 text-white px-4 text-center'
                style={{ fontSize: "35px" }}
              >
                Residential Solar Rooftops
              </h6>
              <nav aria-label='breadcrumb'>
                <ol
                  className='breadcrumb justify-content-center mb-0'
                  style={{
                    background: "transparent",
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    gap: "7px",
                  }}
                >
                  <li className='breadcrumb-item'>
                    <a
                      href={routes.home}
                      className='text-white'
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </a>
                  </li>
                  <span style={{ color: "white" }}>{">"}</span> Residential
                  Solar Rooftops
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* card section 2 */}
        {/* <div className="container d-flex justify-content-center p-2 text-center my-5">
          <div
            className="position-relative rounded px-4"
            style={{
              backgroundColor: "#0692D0",
              padding: "10px 0",
              height: "auto",
              maxHeight: "100%",
              width: "800px",
              maxWidth: "100%",
            }}
          >
            <h4 className="text-center text-sm-left p-4 text-black font-bold">
              Suntek made installation & operations easy
            </h4>
            <p
              className="text-center text-white text-sm-left px-4 py-4 text-black font-bold"
              style={{ marginTop: "-40px" }}
            >
              Suntek energy systems have installed our solar panels for our
              home. And we’ve had no issues over the last 4 years. It is a joy
              to know that not only is our home and home equipment’s powered by
              clean energy. Suntek team have been proactive and made
              installation & operations easy. I’ve recommended them to several
              of our friends and will continue to do so.
            </p>
            <div className="d-flex justify-content-left ml-4 align-items-center text-center gap-3">
              <div
                className="bg-white rounded-circle d-flex align-items-center justify-content-center me-3"
                style={{ width: "50px", height: "50px" }}
              >
                <i className="bi bi-person" style={{ color: "black" }}></i>
              </div>
              <h4 className="mt-3 ml-2">Varun Naidu</h4>
            </div>
          </div>
        </div> */}
        <div className='col-md-12'>
          <div
            className=' ttm-textcolor-white'
            style={{ backgroundColor: "#0692D0" }}
          >
            <div className='layer-content'>
              <div className='col-lg-12 testimonial-slide style1'>
                <div className='text-center testimonials'>
                  <div className='testimonial-caption'>
                    {/* testimonials-caption */}
                    <h4>Effortless Installation and Seamless Operations with StarpowerX
                    </h4>

                    <blockquote>
                    We chose StarpowerX Energy Systems to install solar panels at our home, and it’s been a fantastic decision. Over the past four years, we’ve experienced zero issues. It’s incredibly satisfying to know that our home and appliances are powered by clean, renewable energy. The StarpowerX team has been proactive, making the installation and operation process seamless. We’ve recommended their services to many friends and will continue to do so.

                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* residential  Title */}
        {/* <div className="container-xl py-5">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center align-items-end">
              <div className="position-relative">
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid"
                    src="https://www.sunteksolar.in/images/res-solar-bg-1.png"
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="section-title with-desc clearfix">
                <div className="title-desc">
                  <div className="container text-center text-sm-left">
                    <h1 className="mb-4 font-weight-bold">
                      Residential Solar Rooftops
                    </h1>

                    <p>
                      Suntek Energy Systems is a leading name in the industry of
                      solar power. With all the expertise we earned over these
                      years Suntek is now one of the notable names for
                      households. Solar Rooftops in the residential sector have
                      a wide array of benefits, including the reduction of
                      carbon footprints and high electricity expenses. Our
                      cutting-edge solar technology seamlessly integrates into
                      your home's architecture, transforming your roof into a
                      clean energy powerhouse.
                    </p>
                    <p>
                      Solar Rooftops for residential spaces offer energy
                      independence to the users by harnessing the abundant power
                      of the sun. Our team of experts ensures a hassle-free
                      transition to solar energy, from the initial consultation
                      to the professional installation of your customized solar
                      system. Our cutting-edge solar rooftop comes with robust
                      build quality and high durability. Join the growing
                      community of environmentally conscious homeowners who have
                      embraced solar energy with Suntek. Contact Suntek to get a
                      quote on Solar Panels for Home Price in India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* advantages section */}
        <div style={{ padding: "40px" }}>
          <div>
            <div className='content-inner '>
              <div className='row whySolar'>
                <div className='col-lg-6'>
                  <div className=' res-991-mt-30 text-left'>
                    <div>
                      <img
                        id='logo-img'
                        className='img-fluid whySolarImg '
                        alt='logo'
                        src='images/res-solar.png'
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div
                  className='col-lg-6 '
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                  }}
                >
                  <div className='text-left'>
                    <h3 className='testimonial-caption'>
                      Residential Solar Rooftops
                    </h3>
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p>
                    StarpowerX Energy Systems has established itself as a leader in the solar power industry. With years of expertise, StarpowerX has become a trusted name for households. Solar rooftops in the residential sector offer numerous benefits, including reducing carbon footprints and lowering electricity bills. Our advanced solar technology seamlessly integrates with your home’s architecture, turning your roof into a clean energy powerhouse.

                    </p>
                    <p>
                    Residential solar rooftops provide energy independence by harnessing the sun’s abundant power. Our team of experts ensures a smooth transition to solar energy, from the initial consultation to the professional installation of your customized solar system. Our state-of-the-art solar rooftops are built to last, offering robust quality and high durability. Join the growing community of eco-conscious homeowners who have embraced solar energy with StarpowerX. Contact StarpowerX today for a quote on solar panels for your home in India.
                    </p>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
        <br />
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
                <div className='text-center'>
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h4 className='title'>
                    Benefits of Choosing StarpowerX <br /> Solar Rooftop Solutions for Your Home

                    </h4>
                  </div>
                </div>
                </div>
                </div>
              </div>
              <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
                <div className='container-xl'>
                  <div
                    className='row justify-content-center'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    {[
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/1.jpg",
                        title: "Energize Your Savings",
                        helperText:
                          "Experience substantial savings on your electricity bills with our innovative solar solutions.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/2.jpg",
                        title: "Roof with a Purpose",
                        helperText:
                          "Transform your unused rooftop into a dynamic hub of solar power.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/3.jpg",
                        title: "Power to Give Back",
                        helperText:
                          "Turn your surplus energy into profit by selling it back to the grid and reap the rewards of your solar investment.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/2.jpg",
                        title: "Future-Proof Your Home",
                        helperText:
                          "Shield yourself from increasing electricity costs with our eco-friendly, sustainable solar energy solutions.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/5.jpg",
                        title: "Trusted Powerhouses",
                        helperText:
                          "Count on our reliable and durable solar systems, designed for superior performance and excellence.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/6.jpg",
                        title: "Embrace the Green Shift",
                        helperText:
                          "Embrace sustainability with our innovative and eco-friendly solar energy solutions. Be a part of the green revolution today.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/7.jpg",
                        title: "Investment that Pays",
                        helperText:
                          "Experience a swift return on investment, with payback in just 3-4 years",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/7.jpg",
                        title: "Worry-Free Energy",
                        helperText:
                          "Effortless upkeep, peak performance – our solar solutions are designed to be self-sufficient.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/residential-rooftops/8.jpg",
                        title: "Cool, Comfy Living",
                        helperText:
                          "Lower your home’s heat absorption with solar panels that offer natural shading.",
                      },
                    ].map((advantage, index) => (
                      <div
                        key={index}
                        className='col-lg-3 col-md-6 col-sm-12 mb-4'
                        style={{ marginTop: "60px" }}
                      >
                        <div className='d-flex flex-column align-items-center'>
                          <img
                            src={advantage.img}
                            className='rounded-circle border border-warning'
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              padding: "10px",
                            }}
                            alt={advantage.title}
                          />
                          <h5
                            className='font-weight-bold mt-3'
                            style={{ color: "white" }}
                          >
                            {advantage.title}
                          </h5>
                          <p className='text-center'>{advantage.helperText}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            
          </div>
        </section>
        {/* Residential Solar Installations */}
        <div className='mt-4' style={{display: "none"}}>
          <h3 className='mb-4 font-weight-bold px-2'>
            Residential Solar Installations
          </h3>
          <div className='row justify-content-center'>
            {[
              // "AV info Pride ",
              // " Canton park ",
              // "Emerald Apartments ",
              // "G.S.V Prasad ",
              // "Kalyans Whistling Wood 3KW",
              // "Luxor Appartments ",
              // "Mr. Ajay 5KW ",
              // "RS Brothers 25 kw ",
              // "SLN Urban ",
              // "Vamshi Krishna 15kw ",
              "Innovate Dynamics  ",
              "Elemental Works",
              "Impact Industries",
              "Blueprint Solutions",
              "Pioneer Fabrication",
              "Vertex Innovations",
              "Quantum Crafts",
              "Fusion Technologies",
              "Synergy Designs",
              "Genesis Fabricators",
              "Axion Industries",
              "Stratos Solutions",
              "Optimum Engineering",
              "Nucleus Dynamics",
              "Paramount Industries",
              "Eclipse Manufacturing",
              "Vanguard Works",
              "Spectrum Innovations",
            ].map((installation, index) => (
              <div
                key={index}
                className='col-lg-6 col-md-6 col-sm-12 mb-3 px-4'
              >
                <div
                  className='p-3 bg-primary text-white  text-center shadow-md'
                  style={{
                    background: "linear-gradient(80deg, #0692D0, #044BA4)",
                    borderRadius: "10px",
                  }}
                >
                  <i className='bi bi-check-circle text-warning mr-2'></i>
                  {installation}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResidentialRooftops;
