import React from "react";
import Header from "../layout/Header";
import routes from "../../constants/routes";

function AboutContent() {

  return (
    <div>
      <Header />
      <section className="ttm-row aboutus-section clearfix">
        <div
          className="position-relative rooftopbg"
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",

          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content */}
          <div className="auto-container">
            <div
              className="row position-relative justify-content-between align-items-center text-white px-4"
              style={{ zIndex: 2, width: "100%" }}
            >
              <h1
                className="display-4 font-weight-bold mb-0 text-white  px-4"
                style={{ fontSize: "35px" }}
              >
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol
                  className="breadcrumb justify-content-center mb-0 "
                  style={{
                    background: "transparent",
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    gap: "7px",
                  }}
                >
                  <li className="breadcrumb-item">
                    <a
                      href={routes.home}
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </a>
                  </li>
                  <span style={{ color: "white" }}>{">"}</span> About Us
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <br />

        <div style={{ padding: "40px" }}>
          <div>
            <div className='content-inner '>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className=' res-991-mt-30 text-left'>
                    <div>
                      <img

                        className='img-fluid '
                        title="single-img-one"
                        alt="single-img-one"
                        src="https://www.sunteksolar.in/images/about-us.png"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div
                  className='col-lg-6 '
                  style={{
                    backgroundColor: "#0692D0",
                    color: "white",
                    padding: "20px",
                  }}
                >
                  <div className='container text-center text-sm-left'>
                    <h1 className="mb-4 font-weight-bold">About Us</h1>
                    {/* <h4 className="mb-4 font-weight-bold text-black">
                    Paving the Way for a Greener Future with Solar Power
                    </h4> */}
                    {/* <p>High-tech industries play an important role in the modern economy, and often experience significantly higher pay than other industries. And Digital solutions have transformed the world’s.</p>
                  <p>Sometimes you get into it for the wrong reasons, &amp; eventually it hits you on the face. These reasons can be drawbak but an eye opener too. These reasons can be drawbak but an eye opener too.</p> */}
                    <p>
                    StarpowerX Solar Energy is a leading innovator in the solar energy industry, specializing in the installation and servicing of high-efficiency solar panels designed to harness the power of the sun.

                    </p>
                    <p>
                    At StarpowerX, we believe that the foundation of a sustainable future lies in putting our customers first. Our unwavering commitment to excellence drives us to deliver not just products, but complete energy solutions tailored to meet the unique needs of each customer. We pride ourselves on our ability to provide swift and effective responses to any inquiries or service requests, ensuring that our customers experience seamless transitions to solar energy.
                    </p>
                    <p>
                    Our team of experts is dedicated to pushing the boundaries of what's possible in solar technology, continually exploring new innovations and refining our processes to make solar energy more accessible. By prioritizing customer satisfaction in every aspect of our business, we aim to create lasting relationships and empower our customers to take control of their energy needs with confidence.
                    </p>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
            {/* content-inner */}
            {/* row end*/}
          </div>
        </div>
        {/* <div className="container text-center my-5" style={{backgroundColor: "#044BA4", color:"white",padding:"20px" }}>
              <p className="mt-5">
              StarpowerX Energy Systems Pvt Ltd is proud to offer internationally certified products and components, ensuring top-notch quality and adherence to industry standards. Our unwavering commitment to excellence has garnered us numerous prestigious awards, including the coveted No.1 Solar Company title from TSREDCO (Govt of Telangana) and MNRE (Govt of India) for Renewable Energy1.

              </p>
              <p className="mt-5">
              With rapid expansion, StarpowerX now boasts a strong team of over 100 on-role employees and more than 200 contract employees across various regions of India. Despite our growth, we remain dedicated to our core values, consistently delivering high-quality solar solutions that positively impact both society and the environment.

              </p>
              <p className="mt-5">
              StarpowerX Energy Systems Pvt Ltd proudly offers internationally certified products and components, guaranteeing top-tier quality and compliance with industry standards. Our dedication to excellence has been recognized with several prestigious awards, including the esteemed No.1 Solar Company title from TSREDCO (Govt of Telangana) and MNRE (Govt of India) for Renewable Energy.


              </p>
            </div> */}
        <div className="container text-center my-5" style={{ backgroundColor: "#044BA4", color: "white", padding: "20px" }}>
          <h4 style={{color:"white"}} className="mb-4 mt-5 font-weight-bold text-black">
          Industry Overview
          </h4>
          <p className="mt-2">
          The solar industry in India has seen remarkable growth over the past decade, driven by the country’s commitment to renewable energy. Leading companies like Tata Power Solar, Adani Solar, and Vikram Solar have set benchmarks in innovation and quality. These companies, along with others such as Waaree Energies and RenewSys, are contributing significantly to India’s goal of achieving 280 GW of solar capacity by 2030

            
          </p>
          <h4 style={{color:"white"}} className="mb-4 mt-5 font-weight-bold text-black">
            Our Commitment
            </h4>
          <p className="mt-2">
          At StarpowerX, we align with the industry’s best practices and standards. We are dedicated to providing high-quality solar solutions that are both efficient and sustainable. Our products are designed to meet the diverse needs of our customers, ensuring reliability and performance.

            
          </p>
          <h4 style={{color:"white"}} className="mb-4 mt-5 font-weight-bold text-black">
            Future Prospects:

            </h4>
          <p className="mt-2">
           As the demand for clean energy continues to rise, StarpowerX is poised to expand its footprint in the solar market. We are continuously investing in research and development to bring innovative solutions to our customers. Our goal is to make solar energy accessible and affordable for all, contributing to a greener and more sustainable future.



          </p>
        </div>
        <br />
        <div >
          <section className='clearfix ttm-row service-section position-relative z-1'>
            <div className='container-xl'>
              <div
                className='row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey'
                style={{ backgroundColor: "white", justifyContent: "center" }}
              >
                <div className='col-md-3'>
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100'
                    style={{ backgroundColor: "#0692D0" }}
                  >

                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>8</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p onMouseEnter={(e) => (e.target.style.color = "#fca10a")}
                          onMouseLeave={(e) => (e.target.style.color = "white")}>
                          Years in the Business</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  {/*  featured-icon-box */}
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white'
                    style={{ backgroundColor: "#044BA4" }}
                  >

                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>100+</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p> Members in Our Team</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
                {/* <div className='col-md-3'>
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100'
                    style={{ backgroundColor: "#0692D0" }}
                  >

                    <div className='featured-content'>
                      <div className='featured-title'>
                        <h5>2 Lakh+</h5>
                      </div>
                      <div className='featured-desc'>
                        <p onMouseEnter={(e) => (e.target.style.color = "#fca10a")}
                          onMouseLeave={(e) => (e.target.style.color = "white")}>
                          Water Heater Customers</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='col-md-3'>
                  {/*  featured-icon-box */}
                  <div
                    className='featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white'
                    style={{ backgroundColor: "#0692D0" }}
                  >

                    <div className='featured-content'>
                      {/*  featured-content */}
                      <div className='featured-title'>
                        {/*  featured-title */}
                        <h5>Rapidly growing</h5>
                      </div>
                      <div className='featured-desc'>
                        {/*  featured-desc */}
                        <p> Total Solar Projects</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>

              </div>
            </div>
          </section>

        </div>
        <div className="container text-center my-5" style={{ backgroundColor: "#0692D0", color: "white", padding: "20px" }}>
          <p className="mt-5">
            StarpowerX products & Components are Internationally Certified ,
            ensuring quality assurance and adherence to industry standards.
            This commitment to excellence has earned us several prestigious
            awards, including the No.1 Solar Company title by TSREDCO (Govt
            of Telangana) and MNRE (Govt of India) for Renewable Energy.
          </p>
        </div>

        <div class="container text-center my-5" style={{ backgroundColor: "#044BA4", color: "white", padding: "20px" }} >
          <h2 class="mb-4" style={{ color: "white" }} >Our Mission and Vision</h2>
          <div class="row"  >
            <div class="col-md-6 mb-6 mt-4">
              <img
                src="https://www.sunteksolar.in/images/mission-icon.png"
                alt="mission1"
                class="img-fluid mb-3 "
                style={{ with: "150px", height: "150px" }}
              />
              <p>
                At StarpowerX Solar, we are dedicated to providing innovative solar solutions that empower both individuals and businesses to embrace renewable energy. Our goal is to contribute to a greener planet and significantly reduce carbon footprints.

              </p>
            </div>
            <div class="col-md-6 mb-6 mt-4">
              <img
                src="https://www.sunteksolar.in/images/vision-icon.png"
                alt="vision1"
                class="img-fluid mb-3"
                style={{ with: "150px", height: "150px" }}
              />
              <p>
                We aspire to be one of the Top 5 Solar EPC Companies in India, with a vision of expanding globally and spearheading the movement towards a sustainable future through the power of solar energy.

              </p>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
}

export default AboutContent;
