import React from "react";
import Header from "../layout/Header";
import './videotestimonils.css';


const testimonialsData = [
    {
      videoSrc: "https://www.youtube.com/embed/A0xyw8jASrY",
      title: "Testimonial 1",
    },
    {
      videoSrc: "https://www.youtube.com/embed/NXoolmNnRxM",
      title: "Testimonial 2",
    },
    {
      videoSrc: "https://www.youtube.com/embed/UbZW7yyntjo",
      title: "Testimonial 3",
    },
    {
        videoSrc: "https://www.youtube.com/embed/xPxKDm94h4c",
        title: "Testimonial 4",
      },
      {
        videoSrc: "https://www.youtube.com/embed/hrHHkM85wAI",
        title: "Testimonial 5",
      },
      {
        videoSrc: "https://www.youtube.com/embed/ok535tNV7rM",
        title: "Testimonial 6",
      },
      {
        videoSrc: "https://www.youtube.com/embed/yrekKwwMG2Q",
        title: "Testimonial 7",
      },
      {
        videoSrc: "https://www.youtube.com/embed/HMdVX1hYlAw",
        title: "Testimonial 8",
      },
      {
        videoSrc: "https://www.youtube.com/embed/RfSpO7cxi0M",
        title: "Testimonial 9",
      },
      {
        videoSrc: "https://www.youtube.com/embed/Fm59ksG43Zs",
        title: "Testimonial 10",
      },
      {
        videoSrc: "https://www.youtube.com/embed/8yqcYTo9Hq8",
        title: "Testimonial 11",
      }
  ];

const VideoTestimonials = () => {
  return (
    <div>
      <Header />
      <br />
      <div className='contact-header text-center text-white'>

        <div className='header-overlay d-flex justify-content-between align-items-center'>
          <h1 className='mb-0 text-white'>Video Testimonials</h1>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb mb-0'>
              <li className='breadcrumb-item'>
                <a href='/' className='text-white'>Home</a>
              </li>
              <li className='breadcrumb-item active text-white' aria-current='page'>
                Video Testimonials
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className='container mt-5'>
        <div className='row'>
        <div className="col-12 text-center mt-[10px]">
                    <h2>Video Testimonials</h2>
                </div>
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className='col-md-4 mb-4'>
              <div className='card'>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item mt-[10px]"
                    src={testimonial.videoSrc}
                    allowFullScreen
                    title={testimonial.title}
                  ></iframe>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoTestimonials;