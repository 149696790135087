import React from "react";
import "./ContactUs.css";
import Header from "../layout/Header";
import routes from "../../constants/routes";

const ContactUs = () => {
  return (
    <>
      <Header />
      {/* Header Section with Background Image */}
      <div
          className="position-relative "
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
            marginTop: "100px",
          }}
        >
          <div
            className="position-absolute w-100 h-100"
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className="auto-container">
          <div
            className="row position-relative justify-content-between align-items-center text-white px-4"
            style={{ zIndex: 2,width:"100%" }}
          >
            <h6
              className="display-4 font-weight-bold mb-0 px-2 text-white text-center"
              style={{ fontSize: "35px" }}
            >
             Contact Us
            </h6>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center mb-0"
                style={{
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  gap: "7px",
                }}
              >
                <li className="breadcrumb-item">
                  <a
                    href={routes.home}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </li>
                <span style={{ color: "white" }}>{">"}</span> Contact Us
              </ol>
            </nav>
          </div>
          </div>
        </div>
      {/* Main Content Section */}
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-6 help'>
            <h4 className='text-muted'>NEED ANY HELP?</h4>
            <h2 className='font-weight-bold'>Get in touch with us</h2>
            <div className='contact-info'>
              <div className='mb-4 d-flex align-items-center'>
                <div className='p-3 text-center icon-box bg-light rounded-circle'>
                  <i className='mb-0 bi bi-telephone-fill h4'></i>
                </div>
                <div className='ms-4'>
                  <h5 className='font-weight-bold'>Call Now</h5>
                  <p className='mb-0'>+91 9988833362</p>
                </div>
              </div>
              <div className='mb-4 d-flex align-items-center'>
                <div className='p-3 text-center icon-box bg-light rounded-circle'>
                  <i className='mb-0 bi bi-envelope-fill h4'></i>
                </div>
                <div className='ms-4'>
                  <h5 className='font-weight-bold'>Email Address</h5>
                  <p className='mb-0'>info@startpowerx.in</p>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center col-md-6 contact'>
            <h6>CONTACT US</h6>
            <h4 className='font-weight-bold'>We're here to assist you</h4>
            <p>For support and assistance or queries</p>
            <button className='btn btn-warning btn-lg font-weight-bold'  style={{ backgroundColor: "#0692D0",color:"white" }}>
              Contact Us
            </button>
          </div>
          <div className='col-md-12'>
            <div className='office-box' >
              <h4 className='office-title'>
                <i className='bi bi-geo-alt-fill' ></i> Address
              </h4>
              <p>Sy No 198, Plot No- 6, 1st Floor, Above HDFC Bank, Sriramnagar Colony, Turkayamjal, Telangana 501510.</p>
            </div>
          </div>
        </div>

        <div className='mt-5 row'>
          <div className='col-md-12'>
            <div className='map-container'>
              {/* <iframe
                title='Suntek Energy Systems Location'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30442.78832482559!2d78.407518!3d17.4970874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91c1da555555%3A0xb3d2b2410dc6b816!2sSuntek%20Energy%20Systems%20Pvt%20Ltd%20-%20Solar%20rooftop%20provider%20since%202008.!5e0!3m2!1sen!2sin!4v1691578331447!5m2!1sen!2sin'
                className='map-frame w-100'
                allowFullScreen=''
                loading='lazy'
              ></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23533.73429567197!2d78.5883573!3d17.274699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba1d048901473%3A0x36e681f00f6ef3db!2sStarpowerX%20Solar%20Energy!5e1!3m2!1sen!2sin!4v1724392816945!5m2!1sen!2sin" 
              className='map-frame w-100'
              allowfullscreen="" loading="lazy" >

              </iframe>
            </div>
          </div>
        </div>
<br />
        {/* Office Information Section */}
        {/* <div className='mt-5 row'>
          <div className='col-md-12'>
            <div className='office-box' >
              <h4 className='office-title'>
                <i className='bi bi-geo-alt-fill' ></i> Address
              </h4>
              <p>Sy No 198, Plot No- 6, 1st Floor, Above HDFC Bank, Sriramnagar Colony, Turkayamjal, Telangana 501510.</p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='office-box'>
              <h4 className='office-title'>
                <i className='bi bi-geo-alt-fill'></i> Factory
              </h4>
              <p>
                Plot No: 73/A/1, Survey No: 272, Phase-III, IDA Jeedimetla,
                Telangana, India
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='office-box'>
              <h4 className='office-title'>
                <i className='bi bi-geo-alt-fill'></i> Branch Office (Mumbai)
              </h4>
              <p>
                1152/3, Ground Floor, Godown No.B-24, Jal Malodi Compound,
                Kalhaer, Bhiwand, Thane, Maharastra
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="mb-5">
          <div className="title-header">
            <h2 className="title">Our Installations</h2>
          </div>
          <iframe src="https://www.google.com/maps/d/embed?mid=1Xvu32fPmTgEvjvykGEFLl7980-NlKiFI&ehbc=2E312F" width="640" height="480">
          </iframe>
        </div> */}
      </div>
    </>
  );
};

export default ContactUs;
