import React, { Component } from "react";
import {
  MDBAnimation,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import "./home.css";
import routes from "../../constants/routes";

const solar1 = "/images/solar1.png";
const solar2 = "/images/solar2.png";
const solar3 = "/images/solar3.png";
const solar4 = "/images/solar4.png";
const heroSliderBg = "/images/bg-image/row-bgimage-8.png";
const banner = "/banner.mp4";

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel
            activeItem={1}
            length={5}
            showControls={true}
            showIndicators={false}
            id='Home_banner'
          >
            <div style={{ marginTop: "6rem" }}>
              <MDBCarouselInner>
                <MDBCarouselItem itemId='1'>
                  <MDBView>
                    <video
                      src={banner}
                      muted
                      controls={false}
                      autoPlay
                      playsInline
                      loop
                      className='w-100 d-block'
                    >
                      <source src={banner} type='video/mp4' />
                    </video>
                  </MDBView>
                  <div className='solar' style={{marginTop:"1rem"}}>
                    <MDBCarouselCaption>
                      <div className=' container-xl'>
                        <div className='carousel-caption-inner banner1 '>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2s'
                          >
                            <p className='text-white text'>
                              Residential Solar Rooftops
                            </p>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2.5s'
                          >
                            <h2 className='text-white ttm-textcolor-white flipInY'>
                              A smart choice for a <br />{" "}
                              <strong className='text-white '>
                                brighter tomorrow
                              </strong>
                            </h2>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='3s'
                          >
                            <p>
                              <button
                                style={{ backgroundColor: "#044BA4",marginTop:"-5px" }}
                                
                                className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'
                              >
                               <a href={routes.residentialRooftops}> view more</a>
                              </button>
                            </p>
                          </MDBAnimation>
                        </div>
                        {/* <img
                        style={{ objectFit: "contain", width: "10%" }}
                        className=''
                        src='/images/solar1.png'
                      /> */}
                      </div>
                    </MDBCarouselCaption>
                  </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId='2'>
                  <MDBView>
                  <video
                      src={banner}
                      muted
                      controls={false}
                      autoPlay
                      playsInline
                      loop
                      className='w-100 d-block'
                    >
                      <source src={banner} type='video/mp4' />
                    </video>
                    {/* <img
                      className='w-100 d-block'
                      src={heroSliderBg}
                      alt='slide'
                    /> */}
                  </MDBView>
                  <div className='solar' style={{marginTop:"1rem"}}>
                    <MDBCarouselCaption>
                      <div className=' container-xl'>
                        <div className='carousel-caption-inner banner1'>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2s'
                          >
                            <p className='text' style={{color:"white"}}>Commercial Solar Rooftops</p>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2.5s'
                          >
                            <h2 className='ttm-textcolor-darkgrey flipInY' style={{color:"white"}}>
                              Your path to <br />{" "}
                              <strong
                                className='ttm-textcolor-skincolor '
                                // style={{ color: "#044BA4" }}
                                style={{color:"white"}}
                              >
                                energy independence
                              </strong>
                            </h2>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='3s'
                          >
                            <p>
                              <button
                                style={{ backgroundColor: "#044BA4",marginTop:"-5px" }}
                                className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'
                              >
                               <a href={routes.commercialRooftops}> view more</a>
                              </button>
                            </p>
                          </MDBAnimation>
                        </div>
                      </div>
                    </MDBCarouselCaption>
                  </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId='3'>
                  <MDBView>
                  <video
                      src={banner}
                      muted
                      controls={false}
                      autoPlay
                      playsInline
                      loop
                      className='w-100 d-block'
                    >
                      <source src={banner} type='video/mp4' />
                    </video>
                  </MDBView>
                  <div className='solar' style={{marginTop:"1rem"}}>
                    <MDBCarouselCaption>
                      <div className=' container-xl'>
                        <div className='carousel-caption-inner banner1'>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2s'
                          >
                            <p className='text' style={{color:"white"}}>Industrial Solar Rooftops</p>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2.5s'
                          >
                            <h2 className='ttm-textcolor-darkgrey flipInY' style={{color:"white"}}>
                              Empowering industries with <br />{" "}
                              <strong
                               style={{color:"white"}}
                                className='ttm-textcolor-skincolor '
                              >
                                solar energy
                              </strong>
                            </h2>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='3s'
                          >
                            <p>
                              <button
                                style={{ backgroundColor: "#044BA4",marginTop:"-5px" }}
                                className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'
                              >
                              <a href={routes.industrialRooftops}> view more</a>
                              </button>
                            </p>
                          </MDBAnimation>
                        </div>
                      </div>
                    </MDBCarouselCaption>
                  </div>
                </MDBCarouselItem>

                <MDBCarouselItem itemId='4'>
                  <MDBView>
                  <video
                      src={banner}
                      muted
                      controls={false}
                      autoPlay
                      playsInline
                      loop
                      className='w-100 d-block'
                    >
                      <source src={banner} type='video/mp4' />
                    </video>
                  </MDBView>
                  <div className='solar' style={{marginTop:"1rem"}}>
                    <MDBCarouselCaption>
                      <div className=' container-xl'>
                        <div className='carousel-caption-inner banner1'>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2s'
                          >
                            <p className='text' style={{color:"white"}}>Refer and Earn</p>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2.5s'
                          >
                            <h2 className='ttm-textcolor-darkgrey flipInY' style={{color:"white"}}>
                              Turn your connections into <br />{" "}
                              <strong
                               style={{color:"white"}}
                                className='ttm-textcolor-skincolor '
                              >
                                rewards
                              </strong>
                            </h2>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='3s'
                          >
                            <p>
                              <button
                                style={{ backgroundColor: "#044BA4",marginTop:"-5px" }}
                                className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'
                              >
                                view more
                              </button>
                            </p>
                          </MDBAnimation>
                        </div>
                      </div>
                    </MDBCarouselCaption>
                  </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId='5'>
                  <MDBView>
                  <video
                      src={banner}
                      muted
                      controls={false}
                      autoPlay
                      playsInline
                      loop
                      className='w-100 d-block'
                    >
                      <source src={banner} type='video/mp4' />
                    </video>
                  </MDBView>
                  <div className='solar' style={{marginTop:"1rem"}}>
                    <MDBCarouselCaption>
                      <div className=' container-xl'>
                        <div className='carousel-caption-inner banner1'>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2s'
                          >
                            <p className='text' style={{color:"white"}}>Financing</p>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='2.5s'
                          >
                            <h2 className='ttm-textcolor-darkgrey flipInY' style={{color:"white"}}>
                              Go Solar Hassle-Free with <br />{" "}
                              <strong
                                style={{color:"white"}}
                                className='ttm-textcolor-skincolor '
                              >
                                StarpowerX's Easy Financing Plans
                              </strong>
                            </h2>
                          </MDBAnimation>
                          <MDBAnimation
                            className='slideInUp'
                            count={1}
                            delay='3s'
                          >
                            <p>
                              <button
                                style={{ backgroundColor: "#044BA4",marginTop:"-5px" }}
                                className='ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white'
                              >
                                view more
                              </button>
                            </p>
                          </MDBAnimation>
                        </div>
                      </div>
                    </MDBCarouselCaption>
                  </div>
                </MDBCarouselItem>
              </MDBCarouselInner>
            </div>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;
