import React from "react";
import Header from "../../layout/Header";
import routes from "../../../constants/routes";

function CommercialRooftops() {
  return (
    <div>
      <Header />
      <section className='ttm-row aboutus-section clearfix '>
        <div
          className='position-relative rooftopbg'
          style={{
            backgroundImage:
              "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "100px 0",
          }}
        >
          <div
            className='position-absolute w-100 h-100'
            style={{
              top: 0,
              left: 0,
              backgroundColor: "#233434",
              zIndex: 1,
              opacity: "0.8",
            }}
          ></div>

          {/* Content header section */}
          <div className='auto-container'>
            <div
              className='row position-relative justify-content-between align-items-center text-white px-4'
              style={{ zIndex: 2, width: "100%" }}
            >
              <h6
                className='display-4 font-weight-bold mb-0 text-white px-4 text-center'
                style={{ fontSize: "35px" }}
              >
                Commercial Solar Rooftops
              </h6>
              <nav aria-label='breadcrumb'>
                <ol
                  className='breadcrumb justify-content-center mb-0'
                  style={{
                    background: "transparent",
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    gap: "7px",
                  }}
                >
                  <li className='breadcrumb-item'>
                    <a
                      href={routes.home}
                      className='text-white'
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </a>
                  </li>
                  <span style={{ color: "white" }}>{">"}</span> Commercial Solar
                  Rooftops
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='col-md-12'>
          <div
            className=' ttm-textcolor-white'
            style={{ backgroundColor: "#0692D0" }}
          >
            <div className='layer-content'>
              <div className='col-lg-12 testimonial-slide style1'>
                <div className='text-center testimonials'>
                  <div className='testimonial-caption'>
                    {/* testimonials-caption */}
                    <h3>Commercial Solar Rooftops</h3>

                    <blockquote>
                    StarpowerX Energy Systems: Leading the way in solar energy innovation. We specialize in cutting-edge commercial rooftop solutions designed for a sustainable and reliable future. Our commercial solar rooftops maximize energy independence and offer the most efficient electricity generation.
                    </blockquote>
                    <blockquote>
                    With extensive expertise in the renewable energy sector, we deliver high-performance solar panels that not only reduce your carbon footprint but also ensure long-term energy savings and rapid ROI for commercial organizations. Our tailored solar solutions are crafted to meet the unique needs of each customer.

                    </blockquote>
                    <blockquote>
                    Our expert team guarantees a seamless experience from concept to installation, utilizing the latest advancements in solar technology. By reducing electricity bills and maximizing energy efficiency, we empower you to take control of your energy future.

                      </blockquote>
                      <blockquote>
                      For precise and reliable services, StarpowerX stands tall as the premier provider of commercial solar solutions.

                      </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* content section 2 */}
        {/* <div className="container text-center my-5">
          <h3 className="mb-4 font-weight-bold text-center text-sm-left ">
            Commercial Solar Rooftops
          </h3>
          <div className="mt-4">
            <p>
              Suntek Energy Systems, A pioneer in the industry of solar energy.
              Suntek is dedicated in providing state of the art Commercial
              rooftop Solutions for a reliable & sustainable future. Commercial
              Solar Rooftops benefits in maximizing energy independence and in
              providing the most efficient way of generating electricity. With
              years of expertise in the renewable energy sector, we pride
              ourselves on delivering high-performance solar panels that not
              only reduce your carbon footprint but also contribute to long-term
              energy savings & also rapid ROI for commercial organizations.
              Suntek is dedicated in providing tailored solar solutions that
              matches the needs of our customer’s commercial needs. We are
              equipped with a solid team of experts who ensures seamless
              experience from concept to installation, leveraging the latest
              advancements in solar technology. From reducing electricity bills
              to maximizing energy efficiency, we empower you to take control of
              your energy future. For our precise & reliable services Suntek
              stands tall as the Best solar solutions provider in commercial
              category.
            </p>
          </div>
        </div> */}
        <br />
        <br />
        <section
          className='clearfix ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes'
          style={{ backgroundColor: "#044BA4" }}
        >
          <div className='ttm-row-wrapper-bg-layer ttm-bg-layer' />
          <div className='container-xl'>
            <div className='row'>
              {/* row */}
              <div className='col-lg-8 offset-lg-2'>
              <div className='text-center'>
                <div className='clearfix text-center section-title with-desc'>
                  <div className='title-header'>
                    <h4 className='title'>
                    Unlock the Benefits of StarpowerX’s <br />
                    Commercial Solar Rooftop Solutions

                    </h4>
                  </div>
                </div>
                </div>
                </div>
              </div>
              <section className='clearfix mt-5 ttm-row service-section position-relative z-1'>
                <div className='container-xl'>
                  <div
                    className='row justify-content-center'
                    style={{ backgroundColor: "#0692D0" }}
                  >
                    {[
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/1.jpg",
                        title:
                          "Energy Efficiency, Business Growth",
                          helperText:"Reduce expenses, increase profitability.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/2.jpg",
                        title:
                          "Optimize Rooftop Space",
                          helperText:"Turn unused areas into solar power hubs",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/3.jpg",
                        title:
                          "Consistent Costs, Reliable Business",
                          helperText:"Protect against unpredictable energy prices.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/4.jpg",
                        title:
                          "Reliable Systems, Long-Term Benefits",
                          helperText:"Invest in durable solar solutions.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/5.jpg",
                        title:
                          "Eco-Friendly Power, Positive Image",
                          helperText:"Adopt green energy, boost your brand’s reputation.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/6.jpg",
                        title:
                          "Quick Payback, Sustainable Savings",
                          helperText:"Achieve ROI in 3-4 years, enjoy long-term financial benefits.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/7.jpg",
                        title:
                          "Accessible Financing for Expansion",
                          helperText:"Partner with lenders for MSME and business growth.",
                      },
                      {
                        img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/8.jpg",
                        title:
                          "Smart Investments, Greater Returns",
                          helperText:"Convert ongoing expenses into manageable EMI payments.",
                      },
                    ].map((advantage, index) => (
                      <div
                        key={index}
                        className='col-lg-3 col-md-6 col-sm-12 mb-4'
                        style={{ marginTop: "60px" }}
                      >
                        <div className='d-flex flex-column align-items-center'>
                          <img
                            src={advantage.img}
                            className='rounded-circle border border-warning'
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              padding: "10px",
                            }}
                            alt={advantage.title}
                          />
                         <h7
                            className='font-weight-bold mt-3'
                            style={{ textAlign: "center", color: "#044BA4" }}
                          >
                            {advantage.title}
                          </h7>
                          <p style={{ textAlign: "center" }}>
                            {advantage.helperText}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
        
        </section>

        <div className='container text-center my-5'>
          {/* Advantages Section */}
          {/* <div className="mb-5">
            <h3 className="mb-4 font-weight-bold text-center text-sm-left ">
              Advantages of Suntek Solar Commercial Rooftop Solutions
            </h3>
            <div className="row justify-content-center">
              {[
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/1.jpg",
                  title:
                    "Energy Savings, Business Profits Cut costs, boost profits.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/2.jpg",
                  title:
                    "Maximize Rooftop Potential Utilize idle space for solar generation.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/3.jpg",
                  title:
                    "Stable Costs, Stable Business Hedge against fluctuating tariffs.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/4.jpg",
                  title:
                    "Dependable Performance, Lasting Impact Invest in reliable solar systems.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/5.jpg",
                  title:
                    "Green Energy, Green Image Embrace clean power, enhance brand image.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/6.jpg",
                  title:
                    "Fast ROI, Bright Future Payback within 3-4 years, secure long-term savings.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/7.jpg",
                  title:
                    "Easy Financing for Growth Collaborative loans for MSMEs & Businesses.",
                },
                {
                  img: "https://www.sunteksolar.in/images/rooftops/commercial-rooftops/8.jpg",
                  title:
                    "Invest Wisely, Reap Benefits Divert lifetime bills to smart EMI payments.",
                },
              ].map((advantage, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-sm-12 mb-4"
                  style={{ marginTop: "60px" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src={advantage.img}
                      className="rounded-circle border border-warning"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        padding: "10px",
                      }}
                      alt={advantage.title}
                    />
                    <h7
                      className="font-weight-bold mt-3"
                      style={{ color: "#877655" }}
                    >
                      {advantage.title}
                    </h7>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          {/* Government Commercial Installations Section */}
          <div style={{display: "none"}}>
            <h3 className='mb-4 font-weight-bold'>
              Government Commercial Installations
            </h3>
            <div className='row justify-content-center'>
              {[
                // "GHMC Hyderabad - 18 Buildings - 500KW",
                // "HIND Mela Vakalpna - 1000KW",
                // "NITWAM - 200KW",
                // "Ward & Deed - 100KW",
                // "Maulana Azad National Urdu University - 100KW",
                // "Jagnath Temple - 27KW",
                // " NIMS Hospitals - 10,000 LPD ",
                // " KEDIA College - 40KW ",
                // " Government Social Welfare Hostels - 30,000 LPD ",

                // " Woxen University - 30,000 LPDMini Gurukulam Schools - 15,000 LPD ",
                // " Mini Gurukulam Schools - 15,000 LPD ",
                // "DRS International School - 10,000LPD ",
                // "Kothagudem Thermal Power Station - 70KW ",

                // "Padmashali Bhavan - 30KW ",
                // "AP Tribunal - 55KW ",
                // "Gubba Corporate Office - 20KW ",
                // "T-Hub Foundation - 67KW ",
                // "Galla Corporate Office - 25KW",
                "Innovate Dynamics  ",
                "Elemental Works",
                "Impact Industries",
                "Blueprint Solutions",
                "Pioneer Fabrication",
                "Vertex Innovations",
                "Quantum Crafts",
                "Fusion Technologies",
                "Synergy Designs",
                "Genesis Fabricators",
                "Axion Industries",
                "Stratos Solutions",
                "Optimum Engineering",
                "Nucleus Dynamics",
                "Paramount Industries",
                "Eclipse Manufacturing",
                "Vanguard Works",
                "Spectrum Innovations",
              ].map((installation, index) => (
                <div key={index} className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                  <div
                    className='p-3 bg-primary text-white text-center shadow-md'
                    style={{
                      background: "linear-gradient(80deg,#0692D0 , #044BA4)",
                      borderRadius: "10px",
                    }}
                  >
                    <i className='bi bi-check-circle text-white mr-2 text-warning'></i>
                    {installation}

                    {/* Manually add icons based on specific entries */}
                    {installation === "NITWAM - 200KW" && (
                      <>
                        <i className='bi bi-camera-video ml-2 text-warning'></i>
                      </>
                    )}
                    {installation === "T-Hub Foundation - 67KW " && (
                      <>
                        <i className='bi bi-camera ml-2  text-warning'></i>
                      </>
                    )}
                    {installation === "VNR VAM - 280KW " && (
                      <>
                        <i className='bi bi-camera ml-2 text-warning'></i>
                      </>
                    )}
                    {installation === "HIND Mela Vakalpna - 1000KW" && (
                      <>
                        <i className='bi bi-camera-video ml-2 text-warning'></i>
                        <i className='bi bi-camera ml-2 text-warning'></i>
                      </>
                    )}
                    {installation === "Ward & Deed - 100KW" && (
                      <>
                        <i className='bi bi-camera-video ml-2 text-warning'></i>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CommercialRooftops;
