export default {
  home: "/",
  aboutUs: "/about-us",
  solarRooftop: "/solar-rooftop",
  residentialRooftops: "/residential-rooftops",
  industrialRooftops: "/industrial-rooftops",
  commercialRooftops: "/commercial-rooftops",
  housingSociety: "/housing-society",
  testimonials: "/testimonials",
  videoTestimonials: "video-testimonials",
  projects: "/projects",
  awards: "/awards",
  awardsGallery1:"/awards-gallery-1",
  awardsGallery2:"/awards-gallery-2",
  awardsGallery3:"/awards-gallery-3",
  awardsGallery4:"/awards-gallery-4",
  events: "/events",
  team:"/team",
  contactUs: "/contact-us",
  clients: "/our-clients",
  GetaQuota:"/get-a-quota",
};
