import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "../../constants/routes";

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler
            id='hamburger1'
            onClick={() => this.toggleSingleCollapse("collapse1")}
          />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownItem className='nav-link' nav href={routes.home}>
                    <span>Home</span>
                  </MDBDropdownItem>
                  {/* <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Homepage 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Home2'}>Homepage 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Home3'}>Homepage 3</MDBDropdownItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                    <span>Header Styles</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Header Style 01</MDBDropdownItem>
                                    <MDBDropdownItem target="_blank" href={process.env.PUBLIC_URL + '/Header_style_02'}>Header Style 02</MDBDropdownItem>
                                    <MDBDropdownItem target="_blank" href={process.env.PUBLIC_URL + '/Header_style_03'}>Header Style 03</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            </MDBDropdownMenu> */}
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem
                    className='nav-link'
                    nav
                    href={routes.aboutUs}
                  >
                    <span>About Us</span>
                  </MDBDropdownItem>
                  {/* <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Aboutus_01'}>About Us 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Aboutus_02'}>About Us 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Services_01'}>Services 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Services_02'}>Services 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Our_expert'}>Our Expert</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Faq'}>FAQs</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact_01'}>Contact Us 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact_02'}>Contact Us 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Error'}>Error Page</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Element'}>Elements</MDBDropdownItem>
                        </MDBDropdownMenu> */}
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Products</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdown>
                      <MDBDropdownToggle href={routes.solarRooftop}  nav caret>
                        <span>Solar Rooftops</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href={routes.residentialRooftops}>
                          Residential Rooftops
                        </MDBDropdownItem>
                        <MDBDropdownItem href={routes.industrialRooftops}>
                          Industrial Rooftops
                        </MDBDropdownItem>
                        <MDBDropdownItem href={routes.commercialRooftops}>
                          Commercial Rooftops
                        </MDBDropdownItem>
                        <MDBDropdownItem href={routes.housingSociety}>
                          Housing Society
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    {/* <MDBDropdownItem href={routes.solarWaterHeaters}>
                      Solar Water Heaters
                    </MDBDropdownItem> */}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              {/* <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav href={routes.financing}>
                    <span>Financing</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog'}>Blog Classic</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_grid'}>Blog Grid View</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_top_image'}>Blog Top Image</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_left_image'}>Blog Left Image</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Single_blog'}>Blog Single View</MDBDropdownItem>
                        </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem> */}
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem className='nav-link' nav href={routes.team}>
                    <span>Team</span>
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    className='nav-link'
                    nav
                    href={routes.GetaQuota}
                  >
                    <span>Get A Quote</span>
                  </MDBDropdownItem>
                  {/* <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Aboutus_01'}>About Us 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Aboutus_02'}>About Us 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Services_01'}>Services 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Services_02'}>Services 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Our_expert'}>Our Expert</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Faq'}>FAQs</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact_01'}>Contact Us 1</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact_02'}>Contact Us 2</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Error'}>Error Page</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Element'}>Elements</MDBDropdownItem>
                        </MDBDropdownMenu> */}
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Gallery</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={routes.projects}>
                      Projects
                    </MDBDropdownItem>
                    {/* <MDBDropdownItem href={routes.awards}>
                      Awards
                    </MDBDropdownItem>
                    <MDBDropdownItem href={routes.events}>
                      Events
                    </MDBDropdownItem> */}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem
                    className='nav-link'
                    nav
                    href={routes.contactUs}
                  >
                    <span>Contact Us</span>
                  </MDBDropdownItem>
                  {/* <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog'}>Blog Classic</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_grid'}>Blog Grid View</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_top_image'}>Blog Top Image</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Blog_left_image'}>Blog Left Image</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Single_blog'}>Blog Single View</MDBDropdownItem>
                        </MDBDropdownMenu> */}
                </MDBDropdown>
              </MDBNavItem>
              {/* <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav href={routes.educateChild}>
                    <span>Educate Child</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Shop'}>Default Shop</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Product_details'}>Single Product Details</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Cart'}>Cart</MDBDropdownItem>
                            <MDBDropdownItem href={process.env.PUBLIC_URL + '/Checkout'}>Checkout</MDBDropdownItem>
                        </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem> */}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
