import React, { Component } from 'react';
import Header from '../layout/Header';
import Slider from 'react-slick';
import routes from '../../constants/routes';
export class Team extends Component {
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
      
            responsive: [{
      
              breakpoint: 1199,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
      
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
      
              breakpoint: 680,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }]
          };
      
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <br />
                <div className="ttm-row aboutus-section clearfix">
                    <div
                        className="position-relative  rooftopbg"
                        style={{
                            backgroundImage:
                               "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            color: "white",
                            padding: "100px 0",
                        }}
                    >
                        <div
                            className="position-absolute w-100 h-100"
                            style={{
                                top: 0,
                                left: 0,
                                backgroundColor: "#233434",
                                zIndex: 1,
                                opacity: "0.8",
                            }}
                        ></div>

                        {/* Content */}
                        <div
                            className="container position-relative d-flex row  justify-content-between align-items-center text-white"
                            style={{ zIndex: 2 }}
                        >
                            <h1 className="display-4 font-weight-bold p-4 mb-0 text-white ">
                                Team
                            </h1>
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb justify-content-center mb-0 "
                                    style={{
                                        background: "transparent",
                                        padding: 0,
                                        margin: 0,
                                        listStyle: "none",
                                        gap: "7px",
                                    }}
                                >
                                    <li className="breadcrumb-item">
                                        <a
                                            href={routes.home}
                                            className="text-white"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <span style={{ color: "white" }}>{">"}</span> Team
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div> 
                <section className="ttm-row process-section  res-991-pt-0 clearfix">
                    <div className="container-xl">
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-5 res-991-text-center">
                                <div className="section-title style2 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        
                                        <h2 className="title">Our <span>Team</span></h2>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                        <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" style={{width:"350px", height:"350px"}} src="https://via.placeholder.com/1920X850/888888.jpg" alt="" />
                                        {/* <div className="ttm-social-links-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a><i className="fab fa-facebook-f" /></a></li>
                                                <li className="social-twitter"><a><i className="fab fa-twitter" /></a></li>
                                                <li className="social-linkedin"><a><i className="fab fa-linkedin-in" /></a></li>
                                                <li className="social-googleplus"><a><i className="fab fa-google-plus-g" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="featured-content box-shadow">
                                        <div className="featured-title">
                                            <h5><a>Team</a></h5>
                                        </div>
                                        {/* <p className="category">Managing Director</p> */}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" style={{width:"350px", height:"350px"}}  src="https://via.placeholder.com/1920X850/888888.jpg" alt="" />
                                        {/* <div className="ttm-social-links-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a><i className="fab fa-facebook-f" /></a></li>
                                                <li className="social-twitter"><a><i className="fab fa-twitter" /></a></li>
                                                <li className="social-linkedin"><a><i className="fab fa-linkedin-in" /></a></li>
                                                <li className="social-googleplus"><a><i className="fab fa-google-plus-g" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="featured-content box-shadow">
                                        <div className="featured-title">
                                            <h5><a>Team</a></h5>
                                        </div>
                                        {/* <p className="category">Business Maneger</p> */}
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" style={{width:"350px", height:"350px"}} src="https://via.placeholder.com/1920X850/888888.jpg" alt="" />
                                        {/* <div className="ttm-social-links-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a><i className="fab fa-facebook-f" /></a></li>
                                                <li className="social-twitter"><a><i className="fab fa-twitter" /></a></li>
                                                <li className="social-linkedin"><a><i className="fab fa-linkedin-in" /></a></li>
                                                <li className="social-googleplus"><a><i className="fab fa-google-plus-g" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="featured-content box-shadow">
                                        <div className="featured-title">
                                            <h5><a>Team</a></h5>
                                        </div>
                                        {/* <p className="category">Head-Factory</p> */}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" style={{width:"350px", height:"350px"}} src="https://via.placeholder.com/1920X850/888888.jpg" alt="" />
                                        {/* <div className="ttm-social-links-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a><i className="fab fa-facebook-f" /></a></li>
                                                <li className="social-twitter"><a><i className="fab fa-twitter" /></a></li>
                                                <li className="social-linkedin"><a><i className="fab fa-linkedin-in" /></a></li>
                                                <li className="social-googleplus"><a><i className="fab fa-google-plus-g" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="featured-content box-shadow">
                                        <div className="featured-title">
                                            <h5><a> Team</a></h5>
                                        </div>
                                        {/* <p className="category">Manager Sales</p> */}
                                    </div>
                                </div>
                            </div>
                            
                            
                          
                           
                        </Slider>
                    </div>
                </section>
            </div>
            )
    }
}


export default Team;