import React, { useEffect, useState } from "react";

const WhatsappBottom = () => {
  return (
    <div
      className='floatWhatsapp'
      style={{
        background: "#0dc152",
        borderRadius: "50%",
      }}
    >
      <a
        href='https://api.whatsapp.com/send?phone=919988833362&text=Hi%20!'
        target='_blank'
      >
        <i class='fab fa-whatsapp' style={{ fontSize: 26, color: "#ffffff" }} />
      </a>
    </div>
  );
};

export default WhatsappBottom;
