import React from "react";
import Header from "../layout/Header";
import "./gallery.css"; 

const Gallery = () => {
    const images = [
        "https://www.sunteksolar.in/images/gallery/t-hub/1.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/2.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/3.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/4.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/5.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/6.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/7.jpeg", 
        "https://www.sunteksolar.in/images/gallery/t-hub/8.jpeg"
    ]; 

    return (
        <>      
        <Header />
        {/* Header Section with Background Image */}
        <div style={{marginTop: '95px'}} className='contact-header text-center text-white'>
                <div className='header-overlay d-flex justify-content-between align-items-center'>
                    <h1 className='mb-0 text-white'>Gallery</h1>
                    <nav aria-label='breadcrumb'>
                        <ol className='breadcrumb mb-0'>
                            <li className='breadcrumb-item'>
                                <a href='/' className='text-white'>Home</a>
                            </li>
                            <li className='breadcrumb-item active text-white' aria-current='page'>
                                Gallery
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Gallery Section */}
            <div className='gallery-container'>
                {images.map((image, index) => (
                    <div key={index} className='gallery-item'>
                        <img src={image} alt={`Gallery Image ${index + 1}`} />
                    </div>
                ))}
            </div>
        </>
    );
}

export default Gallery;
