import React, { Component } from 'react';
import Header from '../layout/Header';
import "./getaquota.css";
import routes from '../../constants/routes';

export class GetaQuota extends Component {
    state = {
        name: '',
        email: '',
        mobile: '',
        category: '',
        location: '',
        message: '',
        successMessage: '',
        loading: false
    };


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const timestamp = new Date().toISOString();
        const { name, email, mobile, category, location, message } = this.state;

        const data = {
            name,
            email,
            mobile,
            category,
            location,
            message,
            time: timestamp
        };

        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycbydNmbpf8WWnWbaLaYSvZqe4Bf57ogduuVAzKE8YMNOtvkfxP5lJlfzHGjOs5ASwf5lSg/exec', {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                this.setState({ 
                    successMessage: 'We have received your enquiry, out team will get in touch with you soon!',
                    name: '',
                    email: '',
                    mobile: '',
                    category: '',
                    location: '',
                    message: ''
                });
            } else {
                this.setState({ successMessage: 'Failed to submit the form. Please try again.' });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            this.setState({ successMessage: 'An error occurred. Please try again later.' });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { name, email, mobile, category, location, message, successMessage, loading } = this.state;

        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <br />
                <div className="ttm-row aboutus-section clearfix">
                    <div
                        className="position-relative rooftopbg "
                        style={{
                            backgroundImage:
                            "url('https://tdgsolar.com.au/wp-content/uploads/2023/04/Top-reasons-to-solar-power-your-business-.jpg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            color: "white",
                            padding: "100px 0",
                        }}
                    >
                        <div
                            className="position-absolute w-100 h-100"
                            style={{
                                top: 0,
                                left: 0,
                                backgroundColor: "#233434",
                                zIndex: 1,
                                opacity: "0.8",
                            }}
                        ></div>

                        {/* Content */}
                        <div
                            className="container position-relative d-flex row  justify-content-between align-items-center text-white"
                            style={{ zIndex: 2 }}
                        >
                            <h1 className="display-4 font-weight-bold p-4 mb-0 text-white ">
                                Get A Quote
                            </h1>
                            <nav aria-label="breadcrumb">
                                <ol
                                    className="breadcrumb justify-content-center mb-0 "
                                    style={{
                                        background: "transparent",
                                        padding: 0,
                                        margin: 0,
                                        listStyle: "none",
                                        gap: "7px",
                                    }}
                                >
                                    <li className="breadcrumb-item">
                                        <a
                                            href={routes.home}
                                            className="text-white"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <span style={{ color: "white" }}>{">"}</span>  Get A Quote
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div >
                    <div className='getaquot' style={{ display: "flex", justifyContent: "center" }}>
                        <div className='getaquota'>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "15px", paddingRight: "15px" }}>
                                <div style={{ display: "flex", flexDirection: "column", fontSize: "28px", fontWeight: "bold" }}>
                                    <p className='quotatext' style={{ lineHeight: "1.6" }} >Quick Enquiry <br />
                                    Get in Touch PM Surya Ghar Free Power Initiative.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <form onSubmit={this.handleSubmit}>
                                    <div style={{ padding: "20px" }}>
                                        <div className='getaquotas' style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                                            <div style={{ width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                                                    <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Name</label>
                                                    <input 
                                                        style={{ padding: "10px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px" }} 
                                                        placeholder="Name" 
                                                        name="name"
                                                        value={name}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Email ID</label>
                                                    <input 
                                                        style={{ padding: "10px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px" }} 
                                                        placeholder="Email ID" 
                                                        name="email"
                                                        value={email}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                                                    <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Mobile No</label>
                                                    <input 
                                                        style={{ padding: "10px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px" }} 
                                                        placeholder="Mobile No" 
                                                        name="mobile"
                                                        value={mobile}
                                                        onChange={this.handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Category</label>
                                                    <select 
                                                        style={{ padding: "10px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px" }} 
                                                        name="category" 
                                                        value={category}
                                                        onChange={this.handleChange}
                                                    >
                                                        <option style={{ fontSize: "15px", marginBottom: "5px" }} value="">Select Category</option>
                                                        <option style={{ fontSize: "15px", marginBottom: "5px" }} value="Residential">Residential</option>
                                                        <option style={{ fontSize: "15px", marginBottom: "5px" }} value="Commercial">Commercial</option>
                                                        <option style={{ fontSize: "15px", marginBottom: "5px" }} value="Industrial">Industrial</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                                                <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Location</label>
                                                <input 
                                                    style={{ padding: "10px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px" }} 
                                                    placeholder="Location" 
                                                    name="location"
                                                    value={location}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <label style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Message</label>
                                                <textarea 
                                                    style={{ fontSize: "18px", borderWidth: "1px", borderColor: "grey", outlineStyle: "none", borderRadius: "5px", padding: "10px", height: "100px" }} 
                                                    placeholder="Message"
                                                    name="message"
                                                    value={message}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                                {/* <button type="submit" style={{ fontSize: "20px", fontWeight: "bold", borderWidth: "1px", borderColor: "grey", borderRadius: "5px", outlineStyle
                                                    : "none", width: "100%", height: "3rem", backgroundColor: "#0692D0", color: "white" }}>
                                                    Submit
                                                </button> */}
                                                <button type="submit" disabled={loading} style={{ fontSize: "20px", fontWeight: "bold", borderWidth: "1px", borderColor: "grey", borderRadius: "5px", outlineStyle
                                                    : "none", width: "100%", height: "3rem", backgroundColor: "#0692D0", color: "white" }}>
                                                    {loading ? (
                                                        <div className="spinner" style={{
                                                            width: '1.5rem',
                                                            height: '1.5rem',
                                                            border: '3px solid #f3f3f3',
                                                            borderTop: '3px solid #0692D0',
                                                            borderRadius: '50%',
                                                            animation: 'spin 1s linear infinite',
                                                            margin: 'auto'
                                                        }}></div>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </button>
                                            </div>
                                            {successMessage && (
                                                <div style={{ textAlign: "center", marginTop: "20px", color: "green", fontWeight: "bold" }}>
                                                    {successMessage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='getimg'>
                            <div className='featured-thumbnail'>
                                <img
                                    style={{ marginTop: "5rem" }}
                                    id='logo-img'
                                    className='img-fluid'
                                    alt='getaquota'
                                    src="images/careers.png"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetaQuota;
